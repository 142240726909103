import React, { useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import { api } from '../../services/api'
import { useContext, useEffect } from 'react'
import MyContext from '../../contexts/myContext'
import styles from "./stylesBarraInformacoes.module.css"
import LogoBarraDash from "./LogoBarraDash.png"

export function BarraInformacoes(props) {

    const { barraLateral, hideBar } = useContext(MyContext)
    const [currentDate, setCurrentDate] = useState('');
    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const daysOfWeek = ['DOMINGO', 'SEGUNDA', 'TERÇA', 'QUARTA', 'QUINTA', 'SEXTA', 'SÁBADO'];

            const dayOfWeek = daysOfWeek[now.getDay()];
            const month = (now.getMonth() + 1).toString().padStart(2, '0'); // +1 para ajustar o mês para iniciar em 1
            const day = now.getDate().toString().padStart(2, '0');
            const year = now.getFullYear();
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');

            const formattedTime = `${hours}:${minutes}`;
            const formattedDate = `${dayOfWeek}, ${day}/${month}/${year}`;

            setCurrentTime(formattedTime);
            setCurrentDate(formattedDate);
        }, 1000); // Atualiza a cada segundo

        return () => clearInterval(interval);
    }, []);

    const [temperature, setTemperature] = useState(null);

    useEffect(() => {
        const fetchWeather = async () => {
            try {
                const ipResponse = await fetch('http://ip-api.com/json/');
                const ipData = await ipResponse.json();

                const apiKey = '695f8f1fbc5c8e2a1f9382dc0c9f613e';

                const url = `https://api.openweathermap.org/data/2.5/weather?lat=${ipData.lat}&lon=${ipData.lon}&appid=${apiKey}`;

                const response = await fetch(url);
                const data = await response.json();

                // Convertendo de Kelvin para Celsius
                const temperatureCelsius = Math.round(data.main.temp - 273.15);

                setTemperature(temperatureCelsius);
            } catch (error) {
                console.error('Erro ao obter a temperatura:', error);
            }
        };


        fetchWeather();
    }, []);

    const [exchangeRate, setExchangeRate] = useState(0);
    const [exchangeRateEuro, setExchangeRateEuro] = useState(0);

    useEffect(() => {
        const fetchExchangeRate = async () => {
            try {
                const response = await fetch('https://www.bcb.gov.br/api/conteudo/pt-br/PAINEL_INDICADORES/cambio');
                const data = await response.json();

                // Encontrar o objeto correspondente ao dólar
                const dolar = data.conteudo.find(item => item.moeda === 'Dólar');

                // Extrair o valor de venda do dólar
                const valorVendaDolar = dolar ? dolar.valorVenda : null;

                setExchangeRate(valorVendaDolar);

                // Encontrar o objeto correspondente ao Euro
                const euro = data.conteudo.find(item => item.moeda === 'Euro');

                // Extrair o valor de venda do Euro
                const valorVendaEuro = euro ? euro.valorVenda : null;

                setExchangeRateEuro(valorVendaEuro);
            } catch (error) {
                console.error('Erro ao obter a cotação do dólar:', error);
            }
        };

        fetchExchangeRate();
    }, []);

    return (
        <div className={`h-[8vh] ${styles.divBarraInfo} ${hideBar ? 'w-screen' : barraLateral ? 'w-[calc(100vw-3.5rem)]' : 'w-[calc(100vw-200px)]'}    bg-primaryColor p-[1rem]  gap-[1rem]  z-50 absolute  bottom-0 flex items-center justify-between`} >
            <div className='text-white-200 font-bold text-[32px]'>
                {currentTime}
            </div>
            <div className='text-white-200 font-bold text-[25px]'>
                {currentDate}
            </div>
            <div className='text-white-200 font-bold text-[25px]'>
                Temp.: {temperature} °C
            </div>
            <div className='text-white-200 font-bold text-[25px]'>
                U$D: R$ {exchangeRate.toFixed(2)}
            </div>
            <div className='flex flex-row items-center gap-[0.6rem]'>
                <div className='text-white-200 font-bold text-[25px]'>
                    €: {exchangeRateEuro.toFixed(2)}
                </div>
                <div className='text-white-200  text-[0.65rem] italic'>
                    Fonte: API Banco Central
                </div>
            </div>
            <div style={{
                width: '7rem',
                height: '3.5rem'
            }} className="select-none flex items-center "
            >
                <img alt='Logo' src={LogoBarraDash} />
            </div>
        </div>
    )
}
