import { useContext, useEffect, useState } from 'react'

import styles from './styleMenuSuperiorMobile.module.css'

import MyContext from '../contexts/myContext'

import LogoInntegraSmall from '../public/PNG/LogoInntegraSmall.png'

import { MenuHamb } from '../public/SVG/MenuHamb'
import { MenuLateralExpandido } from './MenuLateralExpandido'
import { api } from '../services/api'


export function MenuSuperiorMobile(props) {
    const { user } = useContext(MyContext)
    const [listaDepartamentos, setListaDepartamentos] = useState(false)

    const [showMenuExpand, setShowMenuExpand] = useState(false)

    function handleSetMenuExpand(){
        setShowMenuExpand(!showMenuExpand)
    }

    async function listaDashboardUsuarios() {
        await api
            .get(`/usuario/api/listaDepartamentosusuario/${user?.cnpj}/${user?.idUsuario}`)
            .then(data => {
                
                setListaDepartamentos(data.data)
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        listaDashboardUsuarios()
    }, [])

    return (
        <>
            <div className={styles.containerMenu} >
                <MenuHamb onClick={() => handleSetMenuExpand()} className={styles.hamb} width="2em" height="2em" color="white" />
                <img className="h-8 w-8" src={LogoInntegraSmall} />

            </div>
            <div style={{ display: showMenuExpand ? 'block' : 'none' }} >
                <MenuLateralExpandido handleSetMenuExpand={handleSetMenuExpand} mobile={true} listaDepartamentos={listaDepartamentos} switchBarraLateral={() => { }} />
            </div>
        </>

    )
}