import FeatherIcon from 'feather-icons-react'

import { BarraDeTitulo } from '../../components/BarraDeTitulo'
import { useContext, useEffect, useState } from 'react'

import Swal from 'sweetalert2'
import { api } from '../../services/api'
import { CloudSave } from '../../public/SVG/CloudSave'

import styles from "./stylesCadastroUsuarios.module.css"
import MyContext from '../../contexts/myContext'

export default function CadastroUsuarios() {
  const { user } = useContext(MyContext)
  const [usuarios, setUsuarios] = useState([])
  const [usuariosSelecionado, setUsuariosSeleciona] = useState('')
  const [IdUsuarioSelecionado, setIdUsuarioSelecionado] = useState('')
  const [dashboards, setDashboards] = useState([])
  const [dashsSelecionados, setDashsSelecionados] = useState([])
  const [disableButton, setDisableButton] = useState(true)

  useEffect(() => {
    getUsuarios()
    getDashboard()
  }, [])

  useEffect(() => {

    if (IdUsuarioSelecionado !== '') {
      listaDashboardUsuarios()
    }
  }, [IdUsuarioSelecionado])

  useEffect(() => {

  }, [dashsSelecionados])


  function populaDashsSelecionados(idDash, checked) {
    if (disableButton) {
      return
    }

    if (checked) {
      setDashsSelecionados([...dashsSelecionados, idDash])
    } else {
      setDashsSelecionados(dashsSelecionados.filter(Id => Id !== idDash))
    }

    const ArrDash = dashboards.filter(Id => Id.Id === idDash)

    ArrDash[0].CheckDash = checked

    const ArrDashFinal = dashboards.filter(Id => Id.Id !== idDash)

    ArrDashFinal.push(ArrDash[0])



    setDashboards(ArrDashFinal)
  }

  function encheCamposTela(nome) {
    setIdUsuarioSelecionado(usuarios[nome].CodigoFuncionario)
    setUsuariosSeleciona(usuarios[nome].nome)

    window.document.getElementById('usuario').value = usuarios[nome].nome
    setDisableButton(false)
  }

  async function listaDashboardUsuarios() {
    await api
      .get(`/usuario/api/listaDashboardUsuario/${user.cnpj}`, { params: { IdUsuario: IdUsuarioSelecionado } })
      .then(data => {

        setDashboards(data.data)
        setDashsSelecionados(data.data.filter(dash => dash.CheckDash === 1).map(dash => {
          return (
            dash.Id
          )
        }))
      })
      .catch(error => console.log(error))
  }

  function handleClearInputs() {
    setUsuariosSeleciona('')
    setIdUsuarioSelecionado('')
    setDashsSelecionados([])
    setDisableButton(true)
    window.document.getElementById('usuario').value = ''
    window.document.getElementById('habilita').value = ''
    window.document.getElementsByName('habilita').checked = false
  }

  async function habilitaDash() {
    if (usuariosSelecionado === '') {
      Swal.fire({
        title: 'Selecione um usuário',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      getUsuarios()
      getDashboard()
      handleClearInputs()
    }
    else {
      await api.delete(`/usuario/api/excluirUsuarioDashboard/${user.cnpj}`, {
        headers: { 'Content-Type': 'application/json' },
        data: { IdUsuario: IdUsuarioSelecionado }
      }).then(async () => {
        if (dashsSelecionados.length === 0) {
          Swal.fire({
            icon: 'success',
            text: 'Cadastro atualizado com sucesso',
            showConfirmButton: false,
            timer: 3000
          })
          getUsuarios()
          getDashboard()
          handleClearInputs()
          return
        }

        await api.post(`/usuario/api/insereUsuariosDash/${user.cnpj}`,
          {
            idUsuario: IdUsuarioSelecionado, dashs: dashsSelecionados
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }

        ).then(() => {
          Swal.fire({
            icon: 'success',
            text: 'Cadastro atualizado com sucesso',
            showConfirmButton: false,
            timer: 3000
          })
          getUsuarios()
          getDashboard()
          handleClearInputs()
        })
      })
        .catch(error => {
          console.log(error)
        })
    }
  }

  async function handleLimpaTelaVoltar() {
    handleClearInputs()
    await getUsuarios()
    await getDashboard()
  }

  const getUsuarios = async () => {
    await api
      .get(`/usuario/api/listaUsuarios/${user.NomeHost}/${user.Porta}/${user.Usuario_BD}/${user.Senha_BD}/${user.NomeBanco}`)
      .then(data => {
        setUsuarios(data.data)
      })
      .catch(error => console.log(error))
  }

  const getDashboard = async () => {
    await api
      .get(`/usuario/api/listaDashboard/${user.cnpj}`)
      .then(data => {
        setDashboards(data.data)
      })
      .catch(error => console.log(error))
  }

  const [orderByDash, setOrderByDash] = useState('NomeAmigavel')
  const [ascDescDash, setAscDescDash] = useState(0)

  const [orderByUser, setOrderByUser] = useState('nome')
  const [ascDescUser, setAscDescUser] = useState(0)

  return (
    <div className="screensDash">

      <div className="flex flex-row h-full w-full">

        <div className="h-full w-full flex flex-col overflow-auto bg-gray-100">
          <BarraDeTitulo Titulo={'CONFIGURAÇÃO DE USUÁRIO'} />

          <div className='flex flex-row gap-2 p-3'>
            <div className='flex w-full flex-row gap-2 items-center  bg-gray-600 rounded-lg p-2'>
              <p className='text-xs md:text-sm 2xl:text-base text-primaryColor whitespace-nowrap'>
                USUÁRIO SELECIONADO:
              </p>
              <input disabled id="usuario" type="text" className='uppercase disabled:bg-gray-100 rounded-lg w-full text-xs md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor' />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',

                width: '97.1%'
              }}
              className={`${styles.divBotaoSalvar} gap-3`}
            >

              <button
                disabled={disableButton}
                style={{
                  fontSize: '16px',
                  width: '40%',
                  justifyContent: 'center',

                  opacity: disableButton ? 0.5 : 1
                }} className={`${styles.botaoSalvar} bg-white-100 ${disableButton && 'cursor-not-allowed'}  md:text-sm 2xl:text-base flex flex-row justify-center lg:justify-start items-center hover:bg-orangeInn-100 text-orange-600 font-semibold hover:text-white-100 py-1 border border-orange-600 rounded shadow-lg`}
                onClick={() => handleLimpaTelaVoltar()}>
                Voltar
              </button>

              <button
                disabled={disableButton}
                style={{
                  fontSize: '16px',
                  width: '40%',
                  justifyContent: 'center',
                  opacity: disableButton ? 0.5 : 1
                }} className={`${styles.botaoSalvar} bg-white-100 ${disableButton && 'cursor-not-allowed'}  md:text-sm 2xl:text-base flex flex-row justify-center lg:justify-start items-center hover:bg-primaryColor text-primaryColor font-semibold hover:text-white-100 py-1 border border-primaryColor rounded shadow-lg`}
                onClick={() => habilitaDash()}>
                <CloudSave width="2em" height="2em" /> &nbsp; Salvar dados
              </button>


            </div>
          </div>

          <div className='flex flex-col sm:flex-row sm:h-[70vh] w-full p-2 gap-2'>
            <div className=" flex flex-col sm:w-[40%] w-full  ">
              <div
                style={{
                  opacity: !disableButton ? 0.5 : 1
                }}
                className='divTable w-full border border-thirdColor overflow-y-auto'>

                <table className='w-full '>
                  <thead>
                    <tr className='bg-primaryColor sticky top-0 text-xs md:text-base 2xl:text-lg text-white-100 text-left'>
                      <th className=' text-center'>Editar</th>
                      <th
                        onClick={() => {
                          setOrderByUser('CodigoFuncionario')
                          setAscDescUser(ascDescUser === 0 ? 1 : 0)
                        }}
                        className='cursor-pointer text-center'>ID</th>
                      <th
                        onClick={() => {
                          setOrderByUser('nome')
                          setAscDescUser(ascDescUser === 0 ? 1 : 0)
                        }}
                        className='cursor-pointer'>Nome</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usuarios.length > 0 ? (
                      usuarios
                        .sort((a, b) =>
                          a[orderByUser] > b[orderByUser]
                            ? ascDescUser === 0
                              ? 1
                              : -1
                            : ascDescUser === 0
                              ? -1
                              : 1
                        )
                        .map((usuario, key) => (
                          <tr key={key} className='odd:bg-white-100 even:bg-lightgray'>
                            <td className='flex flex-row justify-center py-2'>
                              <button disabled={!disableButton} className={`xl:text-sm 2xl:text-base hover:bg-primaryColor text-primaryColor font-semibold hover:text-white-100 py-1 px-2 border border-primaryColor rounded mr-1 ${!disableButton && 'cursor-not-allowed'} `}
                                onClick={() => encheCamposTela(key)}>
                                <FeatherIcon icon="edit" size={14} />
                              </button>
                            </td>
                            <td className='text-center whitespace-nowrap'>{usuario.CodigoFuncionario}</td>
                            <td className='whitespace-nowrap'>{usuario.nome}</td>

                          </tr>
                        ))
                    ) : (
                      <tr className='bg-white-100 w-full'>
                        <td className='text-center italic text-sm' colSpan="3">Nenhum registro encontrado</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              disabled={disableButton}
              style={{
                opacity: disableButton ? 0.5 : 1
              }}
              className='sm:w-[60%] w-full border border-x-thirdColor border-t-thirdColor border-b-gray-100  overflow-y-auto'>

              <table className='w-full'>
                <thead className='sticky top-0'>
                  <tr className='bg-primaryColor text-xs md:text-base 2xl:text-lg text-white-100 text-left'>
                    <th className=' text-center'>Ativar</th>
                    <th
                      onClick={() => {
                        setOrderByDash('Id')
                        setAscDescDash(ascDescDash === 0 ? 1 : 0)
                      }}
                      className='cursor-pointer'>Id</th>
                    <th onClick={() => {
                      setOrderByDash('NomeAmigavel')
                      setAscDescDash(ascDescDash === 0 ? 1 : 0)
                    }}
                      className='cursor-pointer'>Nome Amigável</th>
                    <th
                      onClick={() => {
                        setOrderByDash('Departamento')
                        setAscDescDash(ascDescDash === 0 ? 1 : 0)
                      }}
                      className='cursor-pointer'>Departamento</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboards.length > 0 ? (
                    dashboards
                      .sort((a, b) =>
                        a[orderByDash] > b[orderByDash]
                          ? ascDescDash === 0
                            ? 1
                            : -1
                          : ascDescDash === 0
                            ? -1
                            : 1
                      )
                      .map((dashs, key) => (
                        <tr key={key} className='odd:bg-white-100 even:bg-lightgray uppercase'>
                          <td className='text-center'>{
                            <input className={`cursor-pointer ${disableButton && 'cursor-not-allowed'}`} checked={dashs.CheckDash} onChange={(e) => populaDashsSelecionados(dashs.Id, e.target.checked)} name='habilita' id='habilita' type='checkbox'></input>
                          }</td>
                          <td >{dashs.Id}</td>
                          <td className='whitespace-nowrap' >{dashs.NomeAmigavel}</td>
                          <td className='whitespace-nowrap'>{dashs.Departamento}</td>
                        </tr>
                      ))
                  ) : (
                    <tr className='bg-white-100 w-full'>
                      <td className='text-center italic text-sm' colSpan="4">Nenhum registro encontrado</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

          </div>















        </div>


      </div>
    </div>
  )
}
