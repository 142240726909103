
import { useEffect, useState } from 'react'
import { BarraDeTitulo } from '../../components/BarraDeTitulo'
import IconeDispositivo from '../../public/PNG/IconeDispositivo.png'
import Swal from 'sweetalert2'
import { api } from '../../services/api'
import { useContext } from 'react'
import MyContext from '../../contexts/myContext'

import "../../components/stylesGuia.css"
import {  useFullScreenHandle } from 'react-full-screen'

import LoadingSpinner from '../../components/Loanding/LoadingSpinner'
import { ModalAgendamento } from './components/ModalAgendamento'

export default function CadastroAgendamentos() {
  const { user } = useContext(MyContext)


  const [dashboards, setDashboards] = useState([])
  
  const [departamento, setDepartamento] = useState('Selecione uma Categoria')
  const [TipoCarrossel, setTipoCarrossel] = useState('IMAGEM')
  const [DashSelecionadoCarrossel, setDashSelecionadoCarrossel] = useState('Selecione um Dash')
  const [descricao, setDescricao] = useState('')
  const [idDashEdit, setIdDashEdit] = useState('')
  const [nomeAmigavel, setNomeAmigavel] = useState('')
  const [departamentos, setDepartamentos] = useState([])

  

  const [minutosCarrossel, setMinutosCarrossel] = useState('')

  const [disable, setDisable] = useState([])

  const [dashFiltro, setDashfiltro] = useState('')
  const [dashboards_Full, setDashboards_Full] = useState([])


  function filtrarArray(arrayDados) {

    let arrayTotal = arrayDados

    if (dashFiltro) {
      const vNomeFiltrado = arrayTotal.filter(item => {
        const itemData = `${item.NomeAmigavel.toUpperCase()}
          ${item.Descricao.toUpperCase()}
        `;

        const textData = dashFiltro.toUpperCase();

        return itemData.indexOf(textData) > -1;
      });

      arrayTotal = vNomeFiltrado
    }

    return arrayTotal
  }

  useEffect(() => {
    setDashboards(filtrarArray(dashboards_Full))
  }, [dashFiltro])


  useEffect(() => {
    getListaDepartamentos()
    getDashBoards()
  }, [])

  useEffect(() => {
    if (descricao && nomeAmigavel && departamento && departamento !== 'Selecione uma Categoria') {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }, [descricao, nomeAmigavel, departamento])



 

  const getListaDepartamentos = async () => {
    await api
      .get(`/departamento/api/listaDepartamento/${user.cnpj}`)
      .then(data => {
        setDepartamentos(data.data)
      })
      .catch(error => console.log(error))
  }

  const getDashBoards = async () => {
    await api
      .get(`/dashboard/api/listaDashboard/${user.cnpj}`)
      .then(data => {
        setDashboards(filtrarArray(data.data))
        setDashboards_Full(data.data)
      })
      .catch(error => console.log(error))

  
  }


  const [DispositivoAtual, setDispositivoAtual] = useState('')
  const [RegistrosDispositivos, setRegistrosDispositivos] = useState([])

  const RenderDispositivo = (props) => {
    return (
      <div
        onClick={() => {
          setDispositivoAtual(props.Dispositivo.DescricaoDispositivo)
        }}
        className='w-full max-w-[23%] shadow-lg overflow-hidden flex flex-col items-center justify-center border border-gray-700 rounded-md p-[0.5rem] relative cursor-pointer hover:border-blue-500 hover:underline'
      >
        <img className="h-[3.5rem] w-[3.5rem]" src={IconeDispositivo} alt='IconeDispositivo' />
        <div className='text-lg font-bold text-gray-700 text-center mb-[0.8rem] max-w-[98%] text-ellipsis'>{props.Dispositivo.DescricaoDispositivo}</div>
        <div className={`absolute bottom-0 w-full py-[0.1rem] text-white-200 text-center text-[0.65rem] ${props.Dispositivo.Online === 'Online' ? 'bg-green-700' : 'bg-red-700'}`}>
          {`Atualizado em ${props.Dispositivo.DataUpdate}`}
        </div>
      </div>
    )
  }

  const [IsLoading, setIsLoading] = useState(false)

  async function getRegistrosDispositivos() {
    try {
      setIsLoading(true)

      await api('/dashboard/api/query_execute', {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: {
          SQL_Execute: `
                    SELECT CodigoDispositivo AS CodigoDispositivo, DescricaoDispositivo AS DescricaoDispositivo,
                    IF(TIMESTAMPDIFF(MINUTE, DataUpdate, NOW()) > 5 ,'Offline','Online') AS Online,
                    DATE_FORMAT(DataUpdate,'%d/%m/%Y - %H:%i') AS DataUpdate
                    FROM Dispositivos WHERE CPF_CNPJ = '${user.cnpj}'
                `
        }
      }).then(async (response) => {
        setRegistrosDispositivos(response.data)
      })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            text: 'Erro ao buscar os Dispositivos.  ' + error,
            showConfirmButton: false,
            timer: 5000
          })
          console.log('erro em getRegistrosDispositivos')
          console.log(error)
        })
    } catch (error) {
      console.log('Erro em getRegistrosDispositivos')
      console.log(error)
    }
    finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getRegistrosDispositivos()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      getRegistrosDispositivos()
    }, parseInt(2) * 60000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.

  }, [])

  return (
    <div className="screensDash div-scroll-hidden h-full">
      <div className="flex flex-row h-full w-full div-scroll-hidden ">
        <div className="flex flex-col h-full  w-full overflow-y-auto div-scroll-hidden ">
          <BarraDeTitulo Titulo={'CADASTRO DE AGENDAMENTOS'} />
          {IsLoading ?
            <LoadingSpinner />
            : <></>
          }
          <div className="px-5 p-4 flex flex-col max-h-[86.5%] overflow-auto ">
            <div className='text-[2rem] text-gray-700 font-bold text-center'>DISPOSITIVOS</div>
            <div className='flex flex-row flex-wrap gap-[1.5rem] items-center justify-center mt-[1.2rem] mx-[8rem] '>
              {RegistrosDispositivos.map(e => {
                return (
                  <RenderDispositivo Dispositivo={e} />
                )
              })}

            </div>
          </div>

          {DispositivoAtual !== '' ?
            <ModalAgendamento
              DispositivoAtual={DispositivoAtual}
              setDispositivoAtual={setDispositivoAtual}
            />
            :
            null
          }
        </div>
      </div>
    </div >
  )
}
