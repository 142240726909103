import { useEffect, useState } from 'react'

import styles from './styles.module.css'
import TextField from '@mui/material/TextField';
import { Button, Checkbox, FormControlLabel, InputAdornment } from '@mui/material';

import AccountCircle from '@mui/icons-material/AccountCircle';

import LoginIcon from '@mui/icons-material/Login';
import ApartmentIcon from '@mui/icons-material/Apartment';

import KeySharp from '@mui/icons-material/KeySharp';
import { api } from '../../services/api';
import Swal from 'sweetalert2';

import Logo_Inndoor from "../../public/PNG/Logo_Inndoor.png"
import { LoadingButton } from '@mui/lab';
import { SwapVerticalCircleOutlined } from '@mui/icons-material';
import { version } from '../../services/version';

export default function Login(props) {

    const [idUsuario, setIdUsuario] = useState('')
    const [vNomeAmigavel, setvNomeAmigavel] = useState('')
    const [senha, setSenha] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [lembrarMe, setlembrarMe] = useState(false)
    const [isLoading, setIsLoading] = useState(false)




    function getManterLoginLocalStorage() {

        try {
            if (localStorage.manterLogin === "true") {
                localStorage.manterLogin = "false"
                handleLogin()
            }
        } catch (error) {
            localStorage.manterLogin = "false"
            console.log('ERRO NO MANTER LOGIN: ' + error)
        }
    }

    async function FazerLogin(empresa) {
        const cnpj_HTML = window.document.getElementById('outlined-basic cnpj').value
        const id_HTML = window.document.getElementById('outlined-basic usuario').value
        const senha_HTML = window.document.getElementById('outlined-password-input senha').value

        const cnpjUnFormat = cnpj_HTML.replace('.', '').replace('.', '').replace('/', '').replace('-', '')

        const UrlUser = `/usuario/api/getdadosusuarios/${id_HTML}/${senha_HTML}/${empresa.NomeHost}/${empresa.Porta}/${empresa.Usuario}/${empresa.Senha}/${empresa.NomeBanco}`
        await api.get(UrlUser)
            .then(data => {
                const dadosUsuario = data.data

                if (dadosUsuario?.length === 0 || dadosUsuario[0]?.SenhaCorreta === 'false') {
                    Swal.fire({
                        icon: 'error',
                        text: 'Usuário ou senha inválidos!',
                        showConfirmButton: false,
                        timer: 4000
                    })
                    return
                }

                const user = {
                    idUsuario: id_HTML,
                    senha: senha_HTML,
                    cnpj: cnpjUnFormat,
                    Nome: dadosUsuario[0]?.Nome,
                    GodMode: dadosUsuario[0]?.GodMode,
                    PerfilConfiguracaoAutonomiaBI: dadosUsuario[0]?.PerfilConfiguracaoAutonomiaBI,
                    Token: empresa.Token,
                    NomeHost: empresa.NomeHost,
                    Porta: empresa.Porta,
                    Usuario_BD: empresa.Usuario,
                    Senha_BD: empresa.Senha,
                    NomeBanco: empresa.NomeBanco
                }


                localStorage.lembrarMe = lembrarMe

                if (lembrarMe) {
                    localStorage.idUsuario = id_HTML
                    localStorage.senha = senha_HTML
                    localStorage.cnpj = cnpjUnFormat
                }
                else {
                    localStorage.removeItem("idUsuario")
                    localStorage.removeItem("senha")
                    localStorage.removeItem("cnpj")
                }

                props.setarUsuario(user)
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    text: 'Erro ao obter os dados do Usuário Erro: ' + error,
                    showConfirmButton: false,
                    timer: 5000
                })
                console.log(error)
            })
    }


    async function handleLogin() {
        try {
            setIsLoading(true)
            let cnpj_HTML = window.document.getElementById('outlined-basic cnpj').value

            if (!cnpj_HTML) {
                cnpj_HTML = cnpj
            }

            const cnpjUnFormat = cnpj_HTML.replace('.', '').replace('.', '').replace('/', '').replace('-', '')

            if (!cnpjUnFormat) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Informe o CNPJ para acessar!',
                    showConfirmButton: false,
                    timer: 5000
                })
                return
            }

            await api.get(`/api/getDadosEmpresaFromCNPJ/${cnpjUnFormat}`)
                .then(data => {
                    const dadosEmpresa = data.data

                    if (dadosEmpresa?.length === 0) {
                        Swal.fire({
                            icon: 'error',
                            text: 'Empresa não cadastrada. Verifique o CNPJ informado e tente novamente!',
                            showConfirmButton: false,
                            timer: 4000
                        })
                        return
                    }

                    FazerLogin(dadosEmpresa[0])
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Erro ao obter os dados da Empresa informada. Erro: ' + error,
                        showConfirmButton: false,
                        timer: 5000
                    })
                    console.log(error)
                })


        } catch (error) {
            console.log('Erro em handleLogin')
            console.log(error)
            Swal.fire({
                icon: 'error',
                text: 'Erro ao obter os dados da Empresa informada. Erro: ' + error,
                showConfirmButton: false,
                timer: 5000
            })
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }

    async function getDadosFromLocalStorage() {
        setIdUsuario(localStorage?.idUsuario || '')
        setSenha(localStorage?.senha || '')
        setCnpj(localStorage?.cnpj || '')
        setlembrarMe(localStorage?.lembrarMe === "true" ? true : false)
    }

    useEffect(() => {
        getNomeEmpresaFromCNPJ(cnpj)
    }, [cnpj])

    async function getNomeEmpresaFromCNPJ(_cnpj) {
        try {
            if (!_cnpj) {
                setvNomeAmigavel('')
                return
            }

            const cnpjUnFormat = _cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '')

            if (cnpjUnFormat.length >= 14) {
                await api('/dashboard/api/query_execute', {
                    method: 'put',
                    headers: { 'Content-Type': 'application/json' },
                    data: {
                        SQL_Execute: `SELECT NomeAmigavel AS NomeAmigavel FROM cnpjlicenciados WHERE CPF_CNPJ = LEFT('${cnpjUnFormat}',14)`
                    }
                }).then(async (response) => {
                    const data = response.data
                    if (data.length > 0) {
                        const { NomeAmigavel } = data[0]
                        setvNomeAmigavel(NomeAmigavel)
                    }
                    else {
                        setvNomeAmigavel('')
                    }
                    console.log('data de getNomeEmpresaFromCNPJ')
                    console.log(data)
                })
            }
            else {
                setvNomeAmigavel('')
            }
        } catch (error) {
            console.log('Erro em getNomeEmpresaFromCNPJ')
            console.log(error)
        }
    }

    if (cnpj) {
        getManterLoginLocalStorage()
    }

    useEffect(() => {
        getDadosFromLocalStorage()
        if (cnpj) {
            getManterLoginLocalStorage()
        }

    }, [])


    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            const form = event.target.form;
            const index = Array.from(form).indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    };

    const cnpjMask = (value) => {
        return value
            .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
            .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
    }

    // Função para exibir a largura do dispositivo
    function exibirLarguraDispositivo() {
        var largura = document.documentElement.clientWidth;
        var altura = document.documentElement.clientHeight;
        return `${largura}x${altura}`;
    }

    const InputCNPJ = () => {
        return (
            <>
                <p className="text-gray-700 text-sm mt-1">CNPJ da Empresa</p>
                <div className="relative flex items-center justify-center w-full mt-[-1rem] ">
                    <span className="absolute left-0">
                        <ApartmentIcon style={{ color: '#707070' }} />
                    </span>
                    <input
                        type="text"
                        onKeyDown={handleEnter}
                        value={cnpjMask(cnpj)}
                        onChange={(e) => setCnpj(e.target.value)}
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        className="pl-[2rem] mt-1 border-b-2 border-gray-400 bg-transparent focus:border-black outline-none w-full"
                        id="outlined-basic cnpj"
                        placeholder="CNPJ da Empresa"
                        //focused
                        required
                    />

                </div>
                <div className='mt-[-0.7rem] flex flex-row gap-[0.5rem] items-center justify-between'>
                    <p className="text-gray-500 text-xs ">Informe somente números</p>
                    <p className={`text-green-500 font-bold text-xs ${document.documentElement.clientWidth > 405 ? 'mt-[-3.5rem]' : ''} `}>{vNomeAmigavel}</p>
                </div>
            </>
        )
    }

    const InputUsuario = () => {
        return (
            <>
                <p className="text-gray-700 text-sm mt-1">Usuário (ID)</p>
                <div className="relative flex items-center justify-center w-full mt-[-1rem] ">
                    <span className="absolute left-0">
                        <AccountCircle style={{ color: '#707070' }} />
                    </span>
                    <input
                        type="text"
                        className="pl-[2rem] mt-1 border-b-2 border-gray-400 bg-transparent focus:border-black outline-none w-full"
                        placeholder="Usuário (ID)"

                        onKeyDown={handleEnter}
                        onChange={(e) => setIdUsuario(e.target.value)}
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                                e.preventDefault()
                            }
                        }}
                        value={idUsuario}
                        required
                        id="outlined-basic usuario"
                    //focused
                    />

                </div>
                <p className="text-gray-500 text-xs mt-[-0.7rem]">Seu ID de acesso</p>
            </>
        )
    }

    const InputSenha = () => {
        return (
            <>
                <p className="text-gray-700 text-sm mt-1">Senha</p>
                <div className="relative flex items-center justify-center w-full mt-[-1rem] ">
                    <span className="absolute left-0">
                        <KeySharp style={{ color: '#707070' }} />
                    </span>
                    <input
                        id="outlined-password-input senha"
                        onKeyDown={handleEnter}
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                        type="password"
                        className="pl-[2rem] mt-1 border-b-2 border-gray-400 bg-transparent focus:border-black outline-none w-full"
                        placeholder="Senha"
                        //focused
                        required
                    />

                </div>
                <p className="text-gray-500 text-xs mt-[-0.7rem]">Sua senha de acesso</p>
            </>
        )

    }


    return (
        <>
            <form className={styles.container}>
                <div className={styles.formLogin} >
                    <div className={styles.LogoDash}>
                        <img src={Logo_Inndoor} alt='' />
                    </div>

                    <div className={styles.Fields} >
                        {InputCNPJ()}
                        {InputUsuario()}
                        {InputSenha()}
                    </div>

                    <div className={styles.divBotaoLogin}>
                        <LoadingButton
                            className={styles.BotaoLogin}
                            onClick={() => handleLogin()}
                            color="inherit"
                            loading={isLoading}
                            loadingPosition="start"
                            id="outlined-password-input btnLogin"
                            endIcon={<LoginIcon />}
                            variant="outlined"
                        >
                            Acessar
                        </LoadingButton>
                    </div>




                    <FormControlLabel
                        control={<Checkbox
                            color='error'
                            checked={lembrarMe}
                            onChange={() => setlembrarMe(!lembrarMe)}
                        />}
                        label="Lembrar neste Navegador"
                    />

                    <p className='text-[0.7rem] text-white-200 absolute bottom-4 right-4'>{version}</p>
                    <p className='text-[0.7rem] text-white-200 absolute bottom-1 right-4'>{exibirLarguraDispositivo()}</p>
                </div>

            </form>

        </>
    )
}
