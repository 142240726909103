import React, { useLayoutEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login'

import './styles/globals.css'
import './index.css'
import MyContext from './contexts/myContext'
import MenuLateral from './components/MenuLateral';
import Departamentos from './pages/Departamentos';
import CadastroDashBoard from './pages/CadastroDashBoard';
import CadastroAgendamentos from './pages/CadastroAgendamentos';
import CadastroImagens from './pages/CadastroImagens';
import CadastroEmpresas from './pages/CadastroEmpresas';
import CadastroUsuarios from './pages/CadastroUsuarios';
import DashPorDepartamento from './pages/DashPorDepartamento';
import RenderLinkDash from './pages/RenderLinkDash';

import { MenuSuperiorMobile } from './components/MenuSuperiorMobile';
import { useEffect } from 'react';

import Carrossel from './Carrossel/Carrossel';
import { api } from './services/api';

const App = () => {
  const [user, setUser] = useState({})
  const [hideBar, setHidebar] = useState(false)

  const [tabsAbertas, setTabsAbertas] = useState([]);
  const [tabAtual, setTabAtual] = useState('');
  const [Departamento, setDepartamento] = useState('');
  const [LinkDash, setLinkDash] = useState('');
  const [barraLateral, setBarraLateral] = useState(true)
  const [dadosDash, setDadosDash] = useState([])


  const getDadosDashBoard = async (user) => {
    if (!user.cnpj) {
      return
    }

    const urlDash = `/dashboard/api/listaDashboard/${user.cnpj}`
    console.log('urlDash')
    console.log(urlDash)

    await api
      .get(urlDash)
      .then(data => {
        setDadosDash(data.data)
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    if (user) {
      getDadosDashBoard(user)
    }
  }, [user])

  useEffect(() => {
    addTab('OPÇÕES')
  }, [])

  function addTab(tab) {

    setTabAtual(tab)

    //Se já existe então não cria a mesma aba novamente
    if (tabsAbertas.filter(tabs => tabs.name == tab).length === 0) {
      let arrTabs = tabsAbertas

      let vID = Math.floor(Date.now() * Math.random()).toString(36)

      arrTabs.push({
        name: tab,
        id: vID
      })

      setTabsAbertas(arrTabs)
    }
  }

  function removeTab(tab) {

    setTabsAbertas(tabsAbertas.filter(tabs => tabs.name !== tab))
    if (tabsAbertas.filter(tabs => tabs.name !== tab).length > 1) {
      if (tab === tabAtual) {
        setTabAtual(tabsAbertas.filter(tabs => tabs.name !== tab)[1].name)
      }
    }
    else {
      setTabAtual('')
    }
  }


  function setarUsuario(user) {
    if (user) {
      setUser(user)
    }
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }


  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useLayoutEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize, { passive: true });
      return () => window.removeEventListener('resize', handleResize, { passive: true });
    }, []);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();


  return (
    <MyContext.Provider value={
      {
        user, setUser,
        hideBar, setHidebar,
        tabsAbertas, setTabsAbertas,
        tabAtual, setTabAtual,
        Departamento, setDepartamento,
        LinkDash, setLinkDash,
        barraLateral, setBarraLateral,
        addTab, removeTab,
      }
    }>
      {user?.Nome ?
        <Router>
          <div style={{ display: 'flex', flexDirection: width < 801 ? 'column' : 'row', height: '100vh', width: '100%' }}>
            <div style={{ display: hideBar ? 'none' : 'block' }} >

              {width < 801 ?
                <MenuSuperiorMobile />
                :
                <>
                  <MenuLateral />
                </>
              }
            </div>
            <Switch>
              <Route path='/' exact component={Home} />
              <Route path='/departamentos' exact component={Departamentos} />
              <Route path='/cadastrodashboard' exact component={CadastroDashBoard} />
              <Route path='/CadastroAgendamentos' exact component={CadastroAgendamentos} />
              <Route path='/cadastroimagens' exact component={CadastroImagens} />
              <Route path='/cadastroempresas' exact component={CadastroEmpresas} />
              <Route path='/cadastrousuarios' exact component={CadastroUsuarios} />
              <Route path='/dashpordepartamento/:departamento' exact component={DashPorDepartamento} />
              <Route path='/renderlinkdash/:linkdash' exact component={RenderLinkDash} />

              {dadosDash.filter(aberta => aberta.Descricao === localStorage.DescricaoDashAtual && aberta.opTipoDash === 'CARROSSEL').length > 0 ?
                dadosDash.filter(tab => tab.Descricao === localStorage.DescricaoDashAtual && tab.opTipoDash === 'CARROSSEL').map(tab => {
                  return (
                    <>
                      <Route path={`/${tab.Descricao}/:NomeInterno`} exact component={Carrossel} />
                      <Route path={`/${tab.Descricao}/:NomeInterno/:hideBar`} exact component={Carrossel} />
                    </>
                  )
                })
                :
                null
              }
            </Switch>
          </div>
        </Router>
        :
        <Login setarUsuario={setarUsuario} />
      }

    </MyContext.Provider>
  )
}

export default App;

