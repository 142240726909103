import { useContext, useEffect } from 'react'
import MyContext from '../../contexts/myContext'
import { useRef } from 'react';
import { useState } from 'react';
import { SVGConfig } from '../../public/SVG/SVGConfig';
import { SVGDashboard } from '../../public/SVG/SVGDashboard';
import { SVGMoreHorizontal } from '../../public/SVG/SVGMoreHorizontal';
import { SVGChartLineUp } from '../../public/SVG/SVGChartLineUp';
import FeatherIcon from 'feather-icons-react'

export function CabecalhoAbas(props) {
    const [isDragging, setIsDragging] = useState(false);
    const [initialScroll, setInitialScroll] = useState(0);
    const [showOptions, setShowOptions] = useState(false)
    const cardRef = useRef(null);

    const handleScroll = (event) => {
        event.preventDefault();

        const scrollSpeed = 100;
        const direction = (event.deltaY > 0) ? 1 : -1;

        cardRef.current.scrollLeft += scrollSpeed * direction;
    };

    const { tabsAbertas, setTabsAbertas, removeTab, tabAtual, setTabAtual, Departamento } = useContext(MyContext)

    const handleMouseDown = (event) => {
        setIsDragging(true);
        setInitialScroll(event.clientX);
    };

    const handleMouseMove = (event) => {
        if (isDragging && cardRef.current) {
            const scroll = initialScroll - event.clientX;
            cardRef.current.scrollLeft += scroll;
            setInitialScroll(event.clientX);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    function getIconeAba(name) {
        if (name === 'departamentos' ||
            name === 'cadastrodashboard' ||
            name === 'CadastroAgendamentos' ||
            name === 'cadastroimagens' ||
            name === 'cadastroempresas' ||
            name === 'cadastrousuarios') {
            return (
                <div className={`text-lg ${tabAtual === name ? 'opacity-100' : 'opacity-60'}`} >
                    <SVGConfig color={'white'} />
                </div>
            )
        }

        if (name === 'dashpordepartamento') {
            return (
                <div className={`text-lg ${tabAtual === name ? 'opacity-100' : 'opacity-60'}`} >
                    <SVGDashboard color={'white'} />
                </div>
            )
        }

        if (name === 'OPÇÕES') {
            return (
                <div className={`text-lg p-1 bg-gray-500 rounded-full`} >
                    {showOptions ?
                        <p className='h-[1.5em] w-[1.5em] text-xs flex justify-center items-center text-[#2e3867] select-none font-bold '>X</p>
                        :
                        <SVGMoreHorizontal color={'#3c4983'} />
                    }
                </div>
            )
        }

        if (name.includes('AJUDA -')) {
            return (
                <div className={`text-lg ${tabAtual === name ? 'opacity-100' : 'opacity-60'}`} >
                    <FeatherIcon className={'text-white-100'} icon={'help-circle'} />
                </div>
            )
        }

        return (
            <>
                <div className={`text-lg ${tabAtual === name ? 'opacity-100' : 'opacity-60'}`} >
                    <SVGChartLineUp color={'white'} />
                </div>
            </>
        )
    }

    function getNomeAmigavelAba(name) {
        if (name === 'dashpordepartamento') {
            return `LISTA DE PUBLICAÇÕES - ${Departamento}`
        }

        if (name === 'renderlinkdash') {
            return `DASH EXTERNO POR LINK`
        }

        if (name === 'departamentos') {
            return `CATEGORIAS`
        }

        if (name === 'cadastrodashboard') {
            return `PUBLICAÇÕES`
        }

        if (name === 'CadastroAgendamentos') {
            return `AGENDAMENTOS`
        }

        if (name === 'cadastroimagens') {
            return `IMAGENS`
        }

        if (name === 'cadastroempresas') {
            return `EMPRESAS`
        }

        if (name === 'cadastrousuarios') {
            return `USUÁRIOS`
        }

        if (name.includes('AJUDA -')) {
            return name
        }

        return props.dadosDash.filter(d => d.Descricao === name)[0]?.NomeAmigavel

    }

    const scrollToBottom = () => {
        if (cardRef.current) {
            cardRef.current.scrollLeft = cardRef.current.scrollWidth;
        }
    };

    useEffect(() => {
        if (showOptions) {
            scrollToBottom()
        }
    }, [showOptions])


    useEffect(() => {
        scrollToBottom()
    }, [tabAtual])

    return (
        <>
            <div
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onWheel={handleScroll}
                ref={cardRef}
                className={`scrollable-div h-[6.7vh]  ${isDragging ? 'dragging' : ''} flex flex-row gap-[0.1rem]  overflow-x-scroll div-scroll-hidden div-scroll-touch`} >
                {tabsAbertas.filter(t => t.name !== 'OPÇÕES').concat(tabsAbertas.filter(tt => tt.name === 'OPÇÕES')).map((tab) => {
                    return (
                        <>
                            <div
                                key={tab.id}
                                className={`${tab.name === 'OPÇÕES' ? 'p-4' : 'p-2'}  flex flex-row gap-2 justify-between items-center ${isDragging ? 'dragging' : 'cursor-pointer'}  text-xs py-0
                                        ${tabAtual === tab.name && tab.name !== 'OPÇÕES' ?
                                        'bg-gray-900 text-white-200'
                                        :
                                        'bg-gray-700 text-gray-300 hover:text-white-100 hover:bg-gray-500'
                                    }
                                        ${tab.name === 'OPÇÕES' ?
                                        'hover:!text-gray-300 hover:!bg-gray-700'
                                        :
                                        ''
                                    }
                                    `}
                                onClick={() => setShowOptions(tab.name === 'OPÇÕES' ? !showOptions : false)}
                            >
                                <div className={`select-none py-2`} onClick={() => setTabAtual(tab.name !== 'OPÇÕES' ? tab.name : tabAtual)}>
                                    {getIconeAba(tab.name)}
                                </div>
                                <div className={`select-none whitespace-nowrap ${tab.name === 'OPÇÕES' ? 'hidden' : 'flex'} py-4`} onClick={() => setTabAtual(tab.name !== 'OPÇÕES' ? tab.name : tabAtual)}>
                                    {getNomeAmigavelAba(tab.name)?.toString()?.toUpperCase()}
                                </div>
                                <button
                                    onClick={() => {
                                        removeTab(tab.name)
                                    }}
                                    type="button"
                                    className={`${tab.name === 'OPÇÕES' ? 'hidden' : 'inline-flex'} justify-center items-center text-white-200 bg-transparent hover:bg-gray-300 hover:text-white-100 rounded-sm text-sm w-6 h-6 ml-auto   `}
                                    data-modal-hide="small-modal"
                                >
                                    <svg className="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 15">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close</span>
                                </button>

                            </div>


                        </>
                    )
                })}
                <div className={`justify-center ${showOptions ? 'flex flex-col' : 'hidden'} `}>
                    <button
                        onClick={() => {
                            setTabsAbertas(tabsAbertas.filter(t => t.name === 'OPÇÕES'))
                            setShowOptions(!showOptions)
                            setTabAtual('')
                        }}
                        className={`text-white-100 whitespace-nowrap select-none ${tabsAbertas.length === 1 ? 'opacity-70 cursor-not-allowed' : 'opacity-100 hover:underline'} bg-black hover:bg-gray-500 p-1 px-4 text-[0.6rem]`}
                        type='button'
                    >Fechar todas as guias
                    </button>
                    <button
                        onClick={() => {
                            setTabsAbertas(tabsAbertas.filter(t => t.name === 'OPÇÕES' || t.name === tabAtual))
                            setShowOptions(!showOptions)
                        }}
                        className={`text-white-100 whitespace-nowrap select-none ${tabsAbertas.length === 1 ? 'opacity-70 cursor-not-allowed' : 'opacity-100 hover:underline'} bg-black hover:bg-gray-500 p-1 px-4 text-[0.6rem]`}
                        type='button'
                    >Fechar todas, menos a atual
                    </button>
                </div>
            </div>
            <div className='bg-blueInn-100 text-blueInn-100 max-h-[0.3rem]'>.</div>
        </>
    )
}
