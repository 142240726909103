import React from 'react'
import "@fontsource/roboto";
import LogoEmpresa from "../public/PNG/LogoInntegracao.png"

export function BarraDeTitulo(props) {
    

    return (
        <div className="sticky top-0 flex flex-row w-full justify-between md:h-14 2xl:h-16 bg-white-100 drop-shadow-lg px-5">
            <div className="LogoDash">
                { /* <img src={LogoDashSemFundo} /> */}
            </div>
            <h1 className='flex items-center font-semibold text-xl sm:text-2xl uppercase titleComponent'>{props.Titulo}</h1>
            <div style={{
                width: '8rem',
                height: '4rem'
            }} className="select-none flex items-center logoEmpresa"
            >
                <img alt='Logo Empresa' src={LogoEmpresa} />
            </div>

        </div>
    )
}