import { useEffect, useState } from 'react'

import FeatherIcon from 'feather-icons-react'
import Swal from 'sweetalert2'
import { parseISO, format } from 'date-fns'
import FormData from 'form-data'
import { DataBasePlug } from '../../public/SVG/DataBasePlug'
import { CloudSave } from '../../public/SVG/CloudSave'
import { api } from '../../services/api'
import { SearchDatabase } from '../../public/SVG/SearchDatabase'

import { BarraDeTitulo } from '../../components/BarraDeTitulo'
import styles from "./stylesCadastroEmpresa.module.css"

export default function CadastroEmpresas() {
  const [empresas, setEmpresas] = useState([])
  const [hostName, setHostName] = useState('')
  const [porta, setPorta] = useState('')
  const [usuario, setusuario] = useState('')
  const [senha, setSenha] = useState('')
  const [bancoSelecionado, setBancoSelecionado] = useState('')
  const [bancoSelecionadoPrincipal, setBancoSelecionadoPrincipal] = useState('')
  const [disableBanco, setDisableBanco] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const [listaDatabase, setListaDatabase] = useState([])

  const [getListDatabaseAuto, setGetListDatabaseAuto] = useState(false)

  async function getListDatabases(bancoToShow) {
    setDisableBanco(true)
    setIsLoading(true)
    await api
      .get(`/api/getListaDatabases/${hostName}/${porta}/${usuario}/${senha}`)
      .then(data => {
        setListaDatabase(data.data)
        setDisableBanco(false)
        setIsLoading(false)
        if ((typeof (bancoToShow) !== 'undefined')) {
          setBancoSelecionado(bancoToShow)
        } else {
          Swal.fire({
            icon: 'success',
            text: 'Lista carregada com sucesso!',
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
      .catch(error => {
        setDisableBanco(true)
        setIsLoading(false)
        Swal.fire({
          icon: 'error',
          text: 'Erro ao obter a lista de empresas com os dados informados! ' + error,
          showConfirmButton: false,
          timer: 3000
        })
        console.log(error)
      })
  }

  useEffect(() => {
    if (hostName && porta && usuario && senha) {
      setDisableBanco(false)
    } else {
      setDisableBanco(true)
    }
  }, [hostName, porta, usuario, senha])

  useEffect(() => {

  }, [bancoSelecionado])

  const [cpfcnpj, setCpfcnpj] = useState('')

  useEffect(() => {
    getEmpresas()
  }, [])

  const uploadFile = async file => {
    if (!cpfcnpj) {
      return Swal.fire({
        icon: 'error',
        text: 'CNPJ ainda não definido! Por favor salve a Empresa e depois entre no modo de edição.',
        showConfirmButton: false,
        timer: 5000
      })
    }

    const formData = new FormData()
    const imagefile = document.querySelector('#fileInput')

    const extensaoArquivo = imagefile?.files[0]?.name?.split('.')?.slice(-1)[0]

    if (typeof (extensaoArquivo) === 'undefined') {
      return
    }


    if (extensaoArquivo.toString().toLowerCase() !== 'png') {
      return Swal.fire({
        icon: 'error',
        text: 'Arquivo inválido! Selecione um PNG!',
        showConfirmButton: false,
        timer: 5000
      })
    }

    const API_ENDPOINT = `/api/upload-photos/${cpfcnpj}/${extensaoArquivo}`
    formData.append('logoempresa', imagefile.files[0])
    document.getElementById('imgPreview').src = window.URL.createObjectURL(imagefile.files[0])

    await api.post(
      API_ENDPOINT,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(response => {
        Swal.fire({
          icon: 'success',
          text: response.data,
          showConfirmButton: false,
          timer: 3000
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          text: 'Erro ao gravar a Imagem. ' + error,
          showConfirmButton: false,
          timer: 5000
        })
        console.log('erro: ' + error)
      })
  }

  useEffect(() => {
    if (getListDatabaseAuto && hostName && porta && usuario && senha && bancoSelecionado) {
      getListDatabases(bancoSelecionado)
      setGetListDatabaseAuto(false)
    }
  }, [getListDatabaseAuto])

  function encheCamposTela(empresa) {

    setCpfcnpj(empresas[empresa].CPF_CNPJ)
    setHostName(empresas[empresa].NomeHost)
    setPorta(empresas[empresa].Porta)
    setusuario(empresas[empresa].Usuario)
    setSenha(empresas[empresa].senha)
    setBancoSelecionado(empresas[empresa].NomeBanco)
    setBancoSelecionadoPrincipal(empresas[empresa].NomeBancoPrincipal || '')
    setGetListDatabaseAuto(true)

    window.document.getElementById('cpfcnpj').value = empresas[empresa].CPF_CNPJ.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    window.document.getElementById('amigavel').value = empresas[empresa].NomeAmigavel
    // window.document.getElementById('nomebanco').value = empresas[empresa].NomeBanco
    // window.document.getElementById('hostname').value = empresas[empresa].NomeHost
    // window.document.getElementById('porta').value = empresas[empresa].Porta
    // window.document.getElementById('usuario').value = empresas[empresa].Usuario
    // window.document.getElementById('senha').value = empresas[empresa].senha
    window.document.getElementById('rgie').value = empresas[empresa].RGIE
    window.document.getElementById('cidade').value = empresas[empresa].Cidade
    window.document.getElementById('dtbi').value = empresas[empresa].dataHabilitacaoBI === null ? '' : format(parseISO(empresas[empresa].dataHabilitacaoBI), 'dd/MM/yyyy')
    window.document.getElementById('nome').value = empresas[empresa].nome
    window.document.getElementById('fantasia').value = empresas[empresa].apelido
    window.document.getElementById('TokenMobile').value = empresas[empresa].Token

    document.getElementById('imgPreview').src = `${api.defaults.baseURL}/api/getLogoEmpresa/${empresas[empresa].CPF_CNPJ}`
  }

  function excluiEmpresas(empresa) {
    const excluiEmpresa = { CPF_CNPJ: empresas[empresa].CPF_CNPJ }
    Swal.fire({
      title: 'Deseja excluir este cadastro ?',
      text: 'Você não será capaz de reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        api('/api/excluirempresas', {
          method: 'delete',
          headers: { 'Content-Type': 'application/json' },
          data: excluiEmpresa
        }).then(() => {
          Swal.fire('Excluído', 'Seu cadastro foi excluído', 'success')
          window.document.getElementById('cpfcnpj').value = ''
          window.document.getElementById('amigavel').value = ''
          window.document.getElementById('TokenMobile').value = ''
          window.document.getElementById('nomebanco').value = ''
          window.document.getElementById('hostname').value = ''
          window.document.getElementById('porta').value = ''
          window.document.getElementById('usuario').value = ''
          window.document.getElementById('senha').value = ''
          window.document.getElementById('rgie').value = ''
          window.document.getElementById('cidade').value = ''
          window.document.getElementById('dtbi').value = ''
          window.document.getElementById('nome').value = ''
          window.document.getElementById('fantasia').value = ''
          getEmpresas()
          handleClearInputs()
        })
          .catch(error => {
            console.log(error)
          })
      }
    })
  }

  async function testarConexao() {
    const NomeBanco = window.document.getElementById('nomebanco').value
    const HostName = window.document.getElementById('hostname').value
    const Porta = window.document.getElementById('porta').value
    const Usuario = window.document.getElementById('usuario').value
    const Senha = window.document.getElementById('senha').value

    if (NomeBanco === '' || NomeBanco === 'Selecione um Banco de Dados') {
      return Swal.fire({
        icon: 'error',
        text: 'Selecione o nome do banco de dados',
        showConfirmButton: false,
        timer: 3000
      })
    }
    if (window.document.getElementById('hostname').value === '') {
      return Swal.fire({
        icon: 'error',
        text: 'Preencha o nome do host',
        showConfirmButton: false,
        timer: 3000
      })
    }
    if (window.document.getElementById('porta').value === '') {
      return Swal.fire({
        icon: 'error',
        text: 'Preencha a porta do host',
        showConfirmButton: false,
        timer: 3000
      })
    }
    if (window.document.getElementById('usuario').value === '') {
      return Swal.fire({
        icon: 'error',
        text: 'Preencha o usuario',
        showConfirmButton: false,
        timer: 3000
      })
    }
    if (window.document.getElementById('senha').value === '') {
      return Swal.fire({
        icon: 'error',
        text: 'Preencha a senha',
        showConfirmButton: false,
        timer: 3000
      })
    }

    await api
      .get(`/api/getDadosEmpresas/${HostName}/${Porta}/${Usuario}/${Senha}/${NomeBanco}`)
      .then(data => {
        if (data.data) {
          Swal.fire({
            icon: 'success',
            text: 'Conexão passou no teste',
            showConfirmButton: false,
            timer: 3000
          })
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Conexão não passou no teste. Motivo: ' + data.data,
            showConfirmButton: false,
            timer: 3000
          })
        }
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          text: 'Conexão não passou no teste. Erro: ' + error + ' ' + error?.response?.data,
          showConfirmButton: false,
          timer: 5000
        })
        console.log(error)
      })
  }

  function handleClearInputs() {
    setCpfcnpj('')
    setHostName('')
    setPorta('')
    setusuario('')
    setSenha('')
    setBancoSelecionado('')
    setBancoSelecionadoPrincipal('')
    window.document.getElementById('cpfcnpj').value = ''
    window.document.getElementById('amigavel').value = ''
    window.document.getElementById('TokenMobile').value = ''
    window.document.getElementById('rgie').value = ''
    window.document.getElementById('cidade').value = ''
    window.document.getElementById('dtbi').value = ''
    window.document.getElementById('nome').value = ''
    window.document.getElementById('fantasia').value = ''
    document.getElementById('imgPreview').src = 'IconeEmpresaAlt.png'
    listaDatabase.length = 0
  }

  async function handleCadastroEmpresa() {
    const JSONEmpresa = {
      NomeAmigavel: window.document.getElementById('amigavel').value,
      NomeBanco: window.document.getElementById('nomebanco').value,
      Porta: window.document.getElementById('porta').value,
      Usuario: window.document.getElementById('usuario').value,
      senha: window.document.getElementById('senha').value,
      NomeHost: window.document.getElementById('hostname').value,
      NomeBancoPrincipal: window.document.getElementById('nomebancoamigavel').value === 'Selecione um Banco de Dados' ? '' : window.document.getElementById('nomebancoamigavel').value,
      Token: window.document.getElementById('TokenMobile').value
    }

    if (window.document.getElementById('nomebanco').value === '') {
      return Swal.fire({
        icon: 'error',
        text: 'Preencha o nome do banco',
        showConfirmButton: false,
        timer: 3000
      })
    }
    if (window.document.getElementById('hostname').value === '') {
      return Swal.fire({
        icon: 'error',
        text: 'Preencha o nome do host',
        showConfirmButton: false,
        timer: 3000
      })
    }
    if (window.document.getElementById('porta').value === '') {
      return Swal.fire({
        icon: 'error',
        text: 'Preencha a porta do host',
        showConfirmButton: false,
        timer: 3000
      })
    }
    if (window.document.getElementById('usuario').value === '') {
      return Swal.fire({
        icon: 'error',
        text: 'Preencha o usuario',
        showConfirmButton: false,
        timer: 3000
      })
    }
    if (window.document.getElementById('senha').value === '') {
      return Swal.fire({
        icon: 'error',
        text: 'Preencha a senha',
        showConfirmButton: false,
        timer: 3000
      })
    }

    testarConexao()

    if (empresas.filter(CPF_CNPJ => CPF_CNPJ.CPF_CNPJ === cpfcnpj).length > 0) {
      await api('/api/atualizaempresas/', {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: JSONEmpresa
      }).then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Cadastro atualizado com sucesso',
          showConfirmButton: false,
          timer: 3000
        })
        getEmpresas()
        handleClearInputs()
      })
        .catch(error => {
          console.log(error)
        })
    } else {
      await api('/api/criarempresa/', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: JSONEmpresa
      }).then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Cadastro criado com sucesso',
          showConfirmButton: false,
          timer: 3000
        })
        getEmpresas()
        handleClearInputs()
      })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            text: 'Erro ao cadastrar a Empresa. ' + error + ' ' + error?.response?.data?.sqlMessage,
            showConfirmButton: false,
            timer: 5000
          })
          console.log(error)
        })
    }
  }

  const getEmpresas = async () => {
    await api
      .get('/api/empresa/getDadosGeral')
      .then(data => {
        setEmpresas(data.data)
      })
      .catch(error => console.log(error))
  }

  return (
    <div className="screensDash">
      <div className="flex flex-row h-full w-full">
        <div className="flex flex-col h-full bg-gray-100 w-full overflow-y-auto">
          <BarraDeTitulo Titulo={'CADASTRO DE EMPRESA'} />
          <div
            className="h-full p-4 flex flex-col mb-32"
          >
            <div
              className='border border-thirdColor '
              style={{
                borderRadius: '0.5rem',
                padding: '0.5rem'

              }}
            >
              <div className='mt-2 flex flex-col h-auto'>
                <div className='ml-2 text-sm md:text-base 2xl:text-lg  text-primaryColor font-semibold'>
                  Setup de Conexão MySQL
                </div>

                <div className={`${styles.divFildsMySQL} flex flex-row mt-2 flex-wrap`}>
                  <div className='mr-2 flex flex-col'>
                    <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                      HOST NAME:
                    </p>
                    <input value={hostName} onChange={event => setHostName(event.target.value)} id='hostname' className='uppercase pl-1 rounded-lg shadow-lg w-36 md:w-52 text-xs md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor' />
                  </div>
                  <div className=' mr-2 flex flex-col'>
                    <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                      PORTA:
                    </p>
                    <input
                      value={porta}
                      onChange={event => setPorta(event.target.value)}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault()
                        }
                      }} id='porta' className='uppercase pl-1 rounded-lg shadow-lg w-16 text-xs md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor' />
                  </div>
                  <div className={`${styles.divUserPassword} flex flex-row flex-wrap`}>
                    <div className='mr-2 flex flex-col'>
                      <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                        USUÁRIO:
                      </p>
                      <input value={usuario} onChange={event => setusuario(event.target.value)} id='usuario' className='uppercase pl-1 text-xs rounded-lg shadow-lg w-20 md:w-52 md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor' />
                    </div>
                    <div className='flex flex-col'>
                      <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                        SENHA:
                      </p>
                      <input value={senha} onChange={event => setSenha(event.target.value)} id='senha' type="password" className='pl-1 text-xs rounded-lg shadow-lg w-32 md:w-56 md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor' />
                    </div>

                  </div>
                </div>
                <div className='flex flex-col lg:flex-row my-2'>

                </div>

                <div className='flex flex-col md:flex-row mb-2'>
                  <div
                    style={{

                      boxSizing: 'border-box'
                    }}
                    className='mr-2 flex flex-col'
                  >
                    <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                      NOME DO BANCO:
                    </p>
                    <div
                      className={`${styles.divBotaoBanco}`}
                      style={{

                        width: '100%',
                        boxSizing: 'border-box'

                      }}
                    >
                      <select
                        value={bancoSelecionado}
                        disabled={disableBanco}
                        onChange={event => setBancoSelecionado(event.target.value)}
                        id="nomebanco" className="uppercase bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-auto p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option selected>Selecione um Banco de Dados</option>
                        {
                          listaDatabase.map(banco => {
                            return (
                              <option key={banco.Database} value={banco.Database}>{banco.Database}</option>
                            )
                          })
                        }
                      </select>

                      <div
                        className={`${styles.divBotoesTestConexao}`}
                      >

                        <button
                          type="button"
                          disabled={disableBanco}
                          style={{
                            display: 'flex',

                            justifyContent: 'center',
                            alignContent: 'center',
                            justifyItems: 'center',
                            alignItems: 'center',

                            opacity: disableBanco ? 0.5 : 1,
                            marginRight: '1rem',
                            padding: '0.5rem'

                          }} className={`bg-white-100  ${disableBanco && 'cursor-not-allowed'} hover:bg-primaryColor text-primaryColor hover:text-white-100 border border-primaryColor rounded shadow-lg`}
                          onClick={() => getListDatabases()}>
                          <SearchDatabase width="2rem" height="2rem" />
                        </button>


                        <button disabled={disableBanco} style={{
                          fontSize: '14px',
                          padding: '0.5rem',
                          opacity: disableBanco ? 0.5 : 1

                        }} className={`bg-white-100 ${disableBanco && 'cursor-not-allowed'}  md:text-sm 2xl:text-base flex flex-row justify-center lg:justify-start items-center hover:bg-primaryColor text-primaryColor font-semibold hover:text-white-100 border border-primaryColor rounded shadow-lg`}
                          onClick={() => testarConexao()}>
                          <DataBasePlug width="2rem" height="2rem" /> &nbsp; Testar Conexão
                        </button>

                      </div>

                    </div>

                  </div>

                  {isLoading &&
                    <div style={{
                      display: 'block',
                      top: '37%',
                      left: '60%',
                      position: 'absolute',
                      transform: 'translate(-50%, -50%)'
                    }}>
                      <div style={{
                        border: '2px solid #ccc',
                        width: '60px',
                        height: '60px',
                        borderRadius: '50%',
                        borderTopColor: '#7159c1',
                        borderLeftColor: '#1ecd97',
                        animation: 'spin 1s infinite ease-in'
                      }}>
                      </div>
                    </div>
                  }

                </div>

              </div>
              <hr />
              <div
                style={{
                  backgroundColor: '#e6e6e6',
                  borderBottomRightRadius: '1rem',
                  borderBottomLeftRadius: '1rem'
                }}
                className='flex flex-col h-auto'>
                <div className='flex pt-2 flex-col xl:flex-row '>
                  <div className='flex flex-col xl:w-2/3 w-full '>
                    <div className='my-1 ml-2 text-sm xl:text-base 2xl:text-lg text-primaryColor font-semibold'>
                      Dados Cadastrais
                    </div>
                    <div className={`${styles.divCNPJ} ml-2 flex flex-row mt-2`}>
                      <div className='flex flex-col'>
                        <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                          CNPJ:
                        </p>
                        <input id="cpfcnpj" type='text'
                          disabled maxLength={14} onChange={(e) => setCpfcnpj(e.target.value)} className='uppercase disabled:bg-gray-100 rounded-lg w-28 md:w-36 text-xs md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor' />
                      </div>
                      <div className={`${styles.divRG} mx-2 flex flex-col`}>
                        <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                          RG. / IE.:
                        </p>
                        <input disabled id="rgie" type='text' onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault()
                          }
                        }} className='uppercase disabled:bg-gray-100 rounded-lg w-24 md:w-36 text-xs md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor' />
                      </div>
                      <div className='flex flex-col'>
                        <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                          CIDADE:
                        </p>
                        <input disabled id="cidade" type="text" className='uppercase disabled:bg-gray-100 rounded-lg w-20 md:w-32 text-xs md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor' />
                      </div>
                      <div>
                        <div className={`${styles.divHabilitacaoBI}  flex flex-col ml-2`}>
                          <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                            HABILITAÇÃO BI:
                          </p>
                          <input disabled id="dtbi" className='uppercase disabled:bg-gray-100 rounded-lg w-20 md:w-36 text-xs md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor text-center' />
                        </div>
                      </div>
                    </div>


                    <div className='ml-2 flex flex-row mt-2'>

                    </div>
                    <div className='flex flex-col xl:flex-row py-2'>
                      <div className='ml-2 mt-2 md:mt-0 flex flex-col'>
                        <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                          NOME / RAZÃO SOCIAL:
                        </p>
                        <input disabled id="nome" className={`${styles.inputNome} uppercase disabled:bg-gray-100 rounded-lg w-52 md:w-80 text-xs md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor`} />
                      </div>
                      <div className='ml-2 mt-2 md:mt-0 flex flex-col'>
                        <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                          APELIDO / FANTASIA:
                        </p>
                        <input disabled id="fantasia" className={`${styles.inputNome} uppercase disabled:bg-gray-100 rounded-lg text-xs md:text-sm 2xl:text-base w-52 md:w-80 focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor`} />
                      </div>
                    </div>
                    <div className='ml-2 mt-2 md:mt-0 mb-2 flex flex-col'>
                      <div
                        className={`${styles.divNomeAmigavel}`}
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <div className='flex flex-col w-full'>
                          <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                            NOME AMIGÁVEL:
                          </p>
                          <input id="amigavel" maxLength={100} className={`${styles.inputNomeAmigavel} uppercase pl-1 rounded-lg shadow-lg text-xs w-52 md:w-80 lg:w-40 md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor`} />
                        </div>
                        <div className={`${styles.divBancoPrincipal} flex flex-col `}>
                          <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                            NOME BANCO PRINCIPAL:
                          </p>
                          <select
                            value={bancoSelecionadoPrincipal}
                            disabled={disableBanco}
                            onChange={event => setBancoSelecionadoPrincipal(event.target.value)}
                            id="nomebancoamigavel" className="w-72 uppercase bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option selected>Selecione um Banco de Dados</option>
                            {
                              listaDatabase.map(banco => {
                                return (
                                  <option key={banco.Database} value={banco.Database}>{banco.Database}</option>
                                )
                              })
                            }
                          </select>
                        </div>

                        <div className='flex flex-col mt-[0.5rem] w-[30%]'>
                          <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
                            TOKEN MOBILE:
                          </p>
                          <input id="TokenMobile" maxLength={100} type='number' className={`${styles.inputNomeAmigavel} uppercase pl-1 rounded-lg shadow-lg text-xs md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor`} />
                        </div>
                      </div>

                    </div>

                  </div>
                  <div
                    style={{ borderRadius: '0.5rem', marginTop: '0.5rem' }}
                    className={`${styles.containerLogo} border border-thirdColor  flex flex-col shadow-lg`}
                  >
                    <div style={{ padding: '1rem' }} className={`${styles.divLogoEmpresa}`}>
                      <div className=' text-sm xl:text-base 2xl:text-lg text-primaryColor font-semibold mb-1'>
                        Logo da Empresa
                      </div>
                      <div style={{}} className='bg-white-100 rounded-lg shadow-lg w-full h-full flex items-center justify-center'>
                        <img id="imgPreview" alt='' src={'IconeEmpresaAlt.png'} className='h-10 w-10 xl:w-14 xl:h-14 2xl:h-24 2xl:w-24' />
                      </div>
                      <label className="block mt-3">
                        <input
                          accept="image/png"
                          disabled={disableBanco}
                          style={{
                            cursor: disableBanco ? 'not-allowed' : 'pointer',
                            opacity: disableBanco ? 0.5 : 1
                          }}
                          className={'file:cursor-pointer block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border file:text-sm file:font-semibold file:bg-white-100 file:border-primaryColor file:text-primaryColor hover:file:bg-primaryColorFocus hover:file:text-white-100'}
                          type="file"
                          id="fileInput"
                          onChange={(e) => uploadFile(e.target.files)}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  style={{

                  }}
                  className='flex flex-col lg:flex-row mt-5 justify-center w-full px-2 lg:pl-0'>

                  <button
                    disabled={disableBanco}
                    style={{
                      fontSize: '16px',
                      width: '20%',
                      justifyContent: 'center',
                      marginBottom: '1rem',
                      opacity: disableBanco ? 0.5 : 1
                    }}
                    className={`${styles.botaoSave} bg-white-100 ml-2 ${disableBanco && 'cursor-not-allowed'}  md:text-sm 2xl:text-base flex flex-row justify-center lg:justify-start items-center hover:bg-primaryColor text-primaryColor font-semibold hover:text-white-100 py-1 px-2 border border-primaryColor rounded shadow-lg`}
                    onClick={() => handleCadastroEmpresa()}>
                    <CloudSave width="2em" height="2em" /> &nbsp; Salvar dados
                  </button>
                </div>
              </div>
            </div>
            <div className='mt-2 border border-thirdColor shadow-lg'>
              <div className='overflow-auto'>
                <table className='bg-gray-100 w-full'>
                  <thead>
                    <tr className='bg-primaryColor'>
                      <th className='w-auto md:w-20 text-center text-xs md:text-base 2xl:text-lg text-white-100'>Ações</th>
                      <th className='w-auto md:w-28 text-center text-xs md:text-base 2xl:text-lg text-white-100'>CNPJ</th>
                      <th className='w-auto md:w-36 text-center text-xs md:text-base 2xl:text-lg text-white-100'>Razão Social</th>
                      <th className='w-auto md:w-36 text-center text-xs md:text-base 2xl:text-lg text-white-100'>Fantasia</th>
                      <th className='w-auto md:w-32 text-center text-xs md:text-base 2xl:text-lg text-white-100'>Nome Amigável</th>
                      <th className='w-auto md:w-32 text-center text-xs md:text-base 2xl:text-lg text-white-100'>Token</th>
                    </tr>
                  </thead>
                  <tbody>
                    {empresas?.length > 0 ? (
                      empresas?.map((empresas, key) => (
                        <tr key={key} className='odd:bg-white-100 even:bg-lightgray'>
                          <td style={{ paddingRight: '1rem' }} className='flex flex-row justify-center py-2 '>
                            <button className='xl:text-sm 2xl:text-base hover:bg-primaryColor text-primaryColor font-semibold hover:text-white-100 py-1 px-2 border border-primaryColor rounded mr-1'
                              onClick={() => encheCamposTela(key)}>
                              <FeatherIcon icon="edit" size={14} />
                            </button>
                            <button className='xl:text-sm 2xl:text-base hover:bg-red-500 text-red-500 font-semibold hover:text-white-100 py-1 px-2 border border-red-500 rounded'
                              onClick={() => excluiEmpresas(key)}>
                              <FeatherIcon icon="trash" size={14} />
                            </button>
                          </td>
                          <td style={{ paddingRight: '1rem', paddingLeft: '0.5rem' }} className='text-center text-xs md:text-sm 2xl:text-base truncate '>{empresas.CPF_CNPJ.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}</td>
                          <td style={{ paddingRight: '1rem' }} className='text-left text-xs md:text-sm 2xl:text-base truncate'>{empresas.nome}</td>
                          <td style={{ paddingRight: '1rem' }} className='text-left text-xs md:text-sm 2xl:text-base truncate'>{empresas.apelido}</td>
                          <td style={{ paddingRight: '1rem' }} className='text-left text-xs md:text-sm 2xl:text-base truncate uppercase'>{empresas.NomeAmigavel}</td>
                          <td style={{ paddingRight: '1rem' }} className='text-left text-xs md:text-sm 2xl:text-base'>{empresas.Token}</td>

                        </tr>
                      ))
                    ) : (
                      <tr className='bg-white-100 w-full'>
                        <td className='text-center italic text-sm' colSpan="6">Nenhum registro encontrado</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
