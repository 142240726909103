import { api } from '../../services/api'
import { useEffect, useState } from 'react'
import { BarraDeTitulo } from '../../components/BarraDeTitulo'
import FeatherIcon from 'feather-icons-react'



import styles from "./stylesDashPorDepartamento.module.css"
import { useContext } from 'react'
import MyContext from '../../contexts/myContext'
import { ListaDashboards } from '../../components/ListaDashboards'


export default function DashPorDepartamento(props) {

    const [dashboards, setDashboards] = useState([])
    const [dashboards_Full, setDashboards_Full] = useState([])


    const [dashFiltro, setDashfiltro] = useState('')
    const { addTab, setLinkDash, user } = useContext(MyContext)


    function filtrarArray(arrayDados) {

        let arrayTotal = arrayDados

        if (dashFiltro) {
            const vNomeFiltrado = arrayTotal.filter(item => {
                const itemData = `${item.NomeAmigavel.toUpperCase()}
                    ${item.Descricao.toUpperCase()}
                `;

                const textData = dashFiltro.toUpperCase();

                return itemData.indexOf(textData) > -1;
            });

            arrayTotal = vNomeFiltrado
        }

        return arrayTotal
    }

    useEffect(() => {
        setDashboards(filtrarArray(dashboards_Full))
    }, [dashFiltro])



    const departamentoProp = props?.match?.params?.departamento || props.Departamento


    const getDashBoardsDepartamento = async () => {
        await api
            .get(`/dashboard/api/listaDashboarddepartamento/${user?.cnpj}/${departamentoProp}/${user?.idUsuario}`)
            .then(data => {
                setDashboards(data.data)
                setDashboards_Full(data.data)

            })
            .catch(error => console.log('erro ao buscar os dash por departamento' + error))
    }

    function handleRenderLinkDash(dash) {

        //localStorage.removeItem("dashboardRecents")

        const storedDashboardRecents = localStorage.getItem('dashboardRecents');

        let dashList = [];

        // Verifica se há dados no localStorage
        if (storedDashboardRecents) {
            // Se houver dados, converte para array e atribui a dashList
            dashList = JSON.parse(storedDashboardRecents);
        }

        const newDashboard = {
            user: user,
            Descricao: dash.Descricao,
            LinkDash: dash.LinkDash,
            NomeAmigavel: dash.NomeAmigavel,
            timestamp: new Date().toISOString()
        };

        // Remove da lista caso exista
        dashList = dashList.filter((dashboard) => dashboard.Descricao !== newDashboard.Descricao)

        dashList.push(newDashboard);

        // Salva a lista atualizada no localStorage
        localStorage.setItem('dashboardRecents', JSON.stringify(dashList));

        if (dash.LinkDash) {
            addTab('renderlinkdash')
            setLinkDash(dash.LinkDash)
        }
        else {
            addTab(dash.Descricao)
        }

    }

    useEffect(() => {
        getDashBoardsDepartamento()
    }, [departamentoProp])


    function handleNewTab(dash) {
        localStorage.manterLogin = "true"
        localStorage.DescricaoDashAtual = dash.Descricao
    }

    return (
        <div className='screensDash '>
            <div className="flex flex-row h-full w-full  ">
                <div className="flex flex-col min-h-[90vh]  h-full bg-gray-100 w-full">
                    <BarraDeTitulo Titulo={`LISTA DE PUBLICAÇÕES - ${departamentoProp} (${dashboards.length || 0})`} />

                    <div className='mx-[2rem]'>
                        <ListaDashboards
                            Altura='h-[75vh]'
                            dashFiltro={dashFiltro}
                            setDashfiltro={setDashfiltro}
                            dashboards={dashboards}
                            showEditButtom={false}
                            encheCamposTela={() => { }}
                            excluiDashBoard={() => { }}
                            handleRenderLinkDash={handleRenderLinkDash}
                            handleNewTab={handleNewTab}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
