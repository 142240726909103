
import { useEffect, useState } from 'react'
import { BarraDeTitulo } from '../../components/BarraDeTitulo'
import FeatherIcon from 'feather-icons-react'
import Swal from 'sweetalert2'
import { CloudSave } from '../../public/SVG/CloudSave'
import { api } from '../../services/api'
import styles from "./stylesCadastroDashBoard.module.css"
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useContext } from 'react'
import MyContext from '../../contexts/myContext'

import "../../components/stylesGuia.css"
import RichTextEditor from '../../components/RichTextEditor'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { MenuExportacao } from '../../components/MenuExportacao'
import { ListaDashboards } from '../../components/ListaDashboards'
import LoadingSpinner from '../../components/Loanding/LoadingSpinner'

export default function CadastroDashBoard() {
  const [textButtonSave, setTextButtonSave] = useState('Cadastrar nova Publicação')
  const [textSegundaAba, setTextSegundaAba] = useState('Cadastrar nova Publicação')
  const [dashboards, setDashboards] = useState([])
  const [ListaDashPermitidosUsuario, setListaDashPermitidosUsuario] = useState([])
  const [empresas, setEmpresas] = useState([])
  const [listaLogAlteracoes, setListaLogAlteracoes] = useState([])
  const [departamento, setDepartamento] = useState('Selecione uma Categoria')
  const [TipoCarrossel, setTipoCarrossel] = useState('IMAGEM')
  const [DashSelecionadoCarrossel, setDashSelecionadoCarrossel] = useState('Selecione um Dash')
  const [descricao, setDescricao] = useState('')
  const [idDashEdit, setIdDashEdit] = useState('')
  const [nomeAmigavel, setNomeAmigavel] = useState('')
  const [departamentos, setDepartamentos] = useState([])
  const [linkDash, setLinkDash] = useState('')
  const [linkCarrossel, setLinkCarrossel] = useState('')
  const [linkHelp, setLinkHelp] = useState('')
  const [DescritivoDash, setDescritivoDash] = useState('')
  const [opTempoAtualizacao, setOpTempoAtualizacao] = useState('NÃO ESPECIFICADO')

  const [minutosTempoAtualizacao, setMinutosTempoAtualizacao] = useState('')
  const [minutosCarrossel, setMinutosCarrossel] = useState('')
  const [diasPeriodoInicial, setDiasPeriodoInicial] = useState('')
  const [opPeriodoInicialPesquisa, setOpPeriodoInicialPesquisa] = useState('NÃO ESPECIFICADO')
  const [opTipoDash, setOpTipoDash] = useState('CARROSSEL')
  const [disable, setDisable] = useState([])

  const [dashFiltro, setDashfiltro] = useState('')
  const [dashboards_Full, setDashboards_Full] = useState([])

  const [ComposicaoCarrossel, setComposicaoCarrossel] = useState([])

  const [isLoadingCarrossel, setIsLoadingCarrossel] = useState(false)

  console.log('departamento')
  console.log(departamento)

  function filtrarArray(arrayDados) {

    let arrayTotal = arrayDados

    if (dashFiltro) {
      const vNomeFiltrado = arrayTotal.filter(item => {
        const itemData = `${item.NomeAmigavel.toUpperCase()}
          ${item.Descricao.toUpperCase()}
        `;

        const textData = dashFiltro.toUpperCase();

        return itemData.indexOf(textData) > -1;
      });

      arrayTotal = vNomeFiltrado
    }

    return arrayTotal
  }

  useEffect(() => {
    setDashboards(filtrarArray(dashboards_Full))
  }, [dashFiltro])

  const [ordemDash, setOrdemDash] = useState(true)
  const [orderBy, setOrderBy] = useState(true)

  const [respeitarRegrasHierarquia, setRespeitarRegrasHierarquia] = useState(false)
  const [ExibirBarraInformacoes, setExibirBarraInformacoes] = useState(false)


  const handleEditorFullScreen = useFullScreenHandle()
  const [isFullScreenEditor, setIsFullScreenEditor] = useState(false)

  function setFullScreenEditor() {
    if (isFullScreenEditor) {
      handleEditorFullScreen.exit()
      setIsFullScreenEditor(false)
    } else {
      handleEditorFullScreen.enter()
      setIsFullScreenEditor(true)
    }
  }



  useEffect(() => {
    getListaDepartamentos()
    getDashBoards()
  }, [])

  useEffect(() => {
    if (descricao && nomeAmigavel && departamento && departamento !== 'Selecione uma Categoria') {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }, [descricao, nomeAmigavel, departamento])


  function scrollTopPage() {
    function scrollToTop(element) {
      element.scrollTop = 0;
    }
    var scrollableElements = document.querySelectorAll('.scrollable');
    scrollableElements.forEach(scrollToTop);
  }

  function handleClearInputs() {
    setTextButtonSave('Cadastrar nova Publicação')
    setTextSegundaAba('Cadastrar nova Publicação')
    setDepartamento('Selecione uma Categoria')
    setDescricao('')
    setIdDashEdit('')
    setNomeAmigavel('')
    setLinkDash('')
    setLinkHelp('')
    setDescritivoDash('')
    setRespeitarRegrasHierarquia(false)
    setExibirBarraInformacoes(false)
    setEmpresas([])
    getEmpresas()
    setListaLogAlteracoes([])
    setOpTempoAtualizacao('NÃO ESPECIFICADO')
    setOpPeriodoInicialPesquisa('NÃO ESPECIFICADO')
    setOpTipoDash('CARROSSEL')
    setDiasPeriodoInicial('')
    setMinutosTempoAtualizacao('')

    //CARROSSEL
    setComposicaoCarrossel([])
    LimpaCamposCarrossel()



    window.document.getElementById('departamento').value = 'Selecione uma Categoria'
    window.document.getElementById('descricao').value = ''
    window.document.getElementById('amigavel').value = ''


    scrollTopPage()

  }


  async function encheCamposTela(key) {

    setIdDashEdit(dashboards[key].Id)
    setLinkDash(dashboards[key].LinkDash)
    setOpTipoDash(dashboards[key].opTipoDash)
    setLinkHelp(dashboards[key].LinkHelp)
    setDescritivoDash(dashboards[key].DescritivoDash)
    setDescricao(dashboards[key].Descricao)
    setNomeAmigavel(dashboards[key].NomeAmigavel)
    setDepartamento(dashboards[key].Departamento)
    setRespeitarRegrasHierarquia(dashboards[key].respeitarRegrasHierarquia)
    setExibirBarraInformacoes(dashboards[key].ExibirBarraInformacoes)
    setOpTempoAtualizacao(dashboards[key].opTempoAtualizacao)
    setMinutosTempoAtualizacao(dashboards[key].minutosTempoAtualizacao)
    setOpPeriodoInicialPesquisa(dashboards[key].opPeriodoInicialPesquisa)
    setOpTipoDash(dashboards[key].opTipoDash)
    setDiasPeriodoInicial(dashboards[key].diasPeriodoInicial)

    window.document.getElementById('amigavel').value = dashboards[key].NomeAmigavel
    window.document.getElementById('descricao').value = dashboards[key].Descricao
    window.document.getElementById('departamento').value = dashboards[key].Departamento

    console.log('dashboards[key].Departamento')
    console.log(dashboards[key].Departamento)

    setTextButtonSave('Atualizar dados')
    setTextSegundaAba('Editando dados da Publicação Nº ' + dashboards[key].Id)

    await getComposicaoCarrossel(dashboards[key].Id)
    await LimpaCamposCarrossel()

    setToggleState(2)

  }

  function handleShowLogs(log) {
    const htmlText = `
    Data: <b>${log.DataAlteracaoFormat}</b><br>
    Usuário: <b>${log.NomeUsuario}</b><br>
    Tipo: <b>${log.acao}</b><br><br>
    ${log.Descricao ? `Nome: <b>${log.Descricao}</b><br>` : ''}
    ${log.IdDepartamento ? `Categoria: <b>${departamentos.filter(dep => dep.Id == log.IdDepartamento)[0]?.Descricao?.toUpperCase()}</b><br>` : ''}
    ${log.NomeAmigavel ? `Nome Amigável: <b>${log.NomeAmigavel}</b><br>` : ''}
    ${log.LinkDash ? `Link: <b>${log.LinkDash}</b><br>` : ''}
    ${log.opTipoDash ? `Tipo: <b>${log.opTipoDash}</b><br>` : ''}
    ${log.LinkHelp ? `Link Help: <b>${log.LinkHelp}</b><br>` : ''}
    ${log.DescritivoDash ? `Descritivo: <b>${log.DescritivoDash}</b><br>` : ''}
    ${log.respeitarRegrasHierarquia !== null ? `Respeitar Hierarquia: <b>${log.respeitarRegrasHierarquia}</b><br>` : ''}
    ${log.ExibirBarraInformacoes !== null ? `Barra de Informações: <b>${log.ExibirBarraInformacoes}</b><br>` : ''}
    ${log.opTempoAtualizacao || log.minutosTempoAtualizacao ? `Tempo de Atualização: <b>${log.opTempoAtualizacao || ''} ${log.minutosTempoAtualizacao !== null && log.minutosTempoAtualizacao ? `${log.minutosTempoAtualizacao}min` : ''} </b><br>` : ''}
    ${log.opPeriodoInicialPesquisa || log.diasPeriodoInicial ? `Período inicial de Pesquisa: <b>${log.opPeriodoInicialPesquisa || ''} ${log.diasPeriodoInicial !== null ? `${log.diasPeriodoInicial} dia(s)` : ''} </b><br>` : ''}
    `
    Swal.fire({
      title: "<i>Alterações</i>",
      html: htmlText,
      confirmButtonText: "Voltar",
    });
  }

  async function deleteImagemFundoDash(idDashDel) {
    const urlDel = `/dashboard/api/excluirImagemFundoDash/${idDashDel}`
    if (idDashDel === '') {
      document.getElementById('imgPreviewDash').src = 'DashPreviewJPG.jpg'
      window.document.getElementById('fileInputDash').value = null
      return
    }

    await api(urlDel, {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
    }).then(() => {
      Swal.fire('Imagem de Fundo', 'Excluída com sucesso', 'success')
      document.getElementById('imgPreviewDash').src = 'DashPreviewJPG.jpg'
      window.document.getElementById('fileInputDash').value = null
    }).catch(error => {
      console.log(error)
    })
  }

  async function DeleteUsuariosDashboards(idDashExclusao) {
    try {
      await api('/dashboard/api/query_execute', {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: { SQL_Execute: `DELETE FROM usuariosdashboards WHERE IdDashboard = ${idDashExclusao} AND CPF_CNPJ = '${user.cnpj}' ` }
      })
    } catch (error) {
      console.log('Erro em DeleteDashUsuarios')
      console.log(error)
    }
  }

  async function InsertUsuariosDashboards(idDashInsert) {
    try {
      await api('/dashboard/api/query_execute', {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: {
          SQL_Execute: `INSERT INTO usuariosdashboards (CPF_CNPJ,Id_Usuario,IdDashboard) VALUES (
            '${user.cnpj}',${user.idUsuario}, ${idDashInsert}
          )
          ` }
      })
    } catch (error) {
      console.log('Erro em InsertUsuariosDashboards')
      console.log(error)
    }
  }

  async function handleExcluirDash(idDashExclusao) {
    try {
      Swal.fire({
        title: `Deseja excluir a Publicação Nº ${idDashExclusao}?`,
        text: 'Você não será capaz de reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          api('/dashboard/api/query_execute', {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: { SQL_Execute: `SELECT * FROM dashboards WHERE ID = ${idDashExclusao} AND CPF_CNPJ = '${user.cnpj}'` }
          }).then(() => {
            api('/dashboard/api/query_execute', {
              method: 'put',
              headers: { 'Content-Type': 'application/json' },
              data: { SQL_Execute: `DELETE FROM dashboards WHERE ID = ${idDashExclusao} AND CPF_CNPJ = '${user.cnpj}'` }
            }).then(() => {
              api('/dashboard/api/query_execute', {
                method: 'put',
                headers: { 'Content-Type': 'application/json' },
                data: { SQL_Execute: `DELETE FROM ComposicaoCarrossel WHERE IdDashboard = ${idDashExclusao} AND CPF_CNPJ = '${user.cnpj}'` }
              })
                .then(async () => {
                  await DeleteUsuariosDashboards(idDashExclusao)
                  Swal.fire('Excluído', 'Publicação excluída com sucesso!', 'success')
                  getDashBoards()
                  getListaDepartamentos()
                  handleClearInputs()
                  await deleteImagemFundoDash(idDashExclusao)
                })
                .catch(error => {
                  console.log(error)
                  Swal.fire({
                    icon: 'error',
                    text: 'Erro ao Excluir a Publicação! ' + error,
                    showConfirmButton: false,
                    timer: 5000
                  })
                })

            }).catch(error => {
              Swal.fire({
                icon: 'error',
                text: 'Erro ao Excluir a Publicação! ' + error,
                showConfirmButton: false,
                timer: 5000
              })
            })
          }).catch(error => {
            console.log(error)
            Swal.fire({
              icon: 'error',
              text: 'Erro ao Excluir a Publicação! ' + error,
              showConfirmButton: false,
              timer: 5000
            })
          })
        }
      })
    } catch (error) {
      console.log('Erro handleExcluirDash')
      console.log(error)
    }
  }

  async function excluiDashBoard(dashboard) {
    try {
      const idDashExclusao = dashboards[dashboard].Id
      await api.get(`/dashboard/api/listaComposicaoCarrossel/${user.cnpj}/${idDashExclusao}`)
        .then(async data => {
          const results = data.data
          if (results.length > 0) {
            Swal.fire({
              icon: 'error',
              text: `Carrossel com composição cadastrada! Remova os arquivos para continuar.`,
              showConfirmButton: false,
              timer: 8000
            })
          }
          else {
            await handleExcluirDash(idDashExclusao)
          }
        })
        .catch(error => {
          console.log('Erro em getComposicaoCarrossel')
          console.log(error)
        })


    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Erro na Exclusão da Publicação ' + error,
        showConfirmButton: false,
        timer: 7000
      })
    }

  }


  const { addTab, setLinkDash: setLinkDashContext, user } = useContext(MyContext)

  async function criaDashboard() {
    const IdDepartamento = departamentos.filter(ID => ID.Descricao === window.document.getElementById('departamento').value)

    const JSONDashboard = {
      Descricao: window.document.getElementById('descricao').value,
      NomeAmigavel: window.document.getElementById('amigavel').value,
      IdDepartamento: IdDepartamento[0].Id,
      LinkDash: linkDash,
      LinkHelp: linkHelp,
      DescritivoDash: DescritivoDash,
      opTipoDash: opTipoDash,
      respeitarRegrasHierarquia: respeitarRegrasHierarquia,
      ExibirBarraInformacoes: ExibirBarraInformacoes,
      opTempoAtualizacao: opTempoAtualizacao,
      minutosTempoAtualizacao: minutosTempoAtualizacao,
      opPeriodoInicialPesquisa: opPeriodoInicialPesquisa,
      diasPeriodoInicial: diasPeriodoInicial,
      UsuarioLog: user.idUsuario,
    }




    if (idDashEdit) {
      const checkDescricao = dashboards?.filter(dash => dash.Id !== idDashEdit && dash.Descricao?.toLowerCase() === window.document.getElementById('descricao').value?.toLowerCase())
      if (checkDescricao.length > 0) {
        Swal.fire({
          icon: 'error',
          text: 'Nome da Publicação já existente!',
          showConfirmButton: false,
          timer: 5000
        })
        return
      }

      const checkNomeAmigavel = dashboards?.filter(dash => dash.Id !== idDashEdit && dash.NomeAmigavel?.toLowerCase() === window.document.getElementById('amigavel').value?.toLowerCase())
      if (checkNomeAmigavel.length > 0) {
        Swal.fire({
          icon: 'error',
          text: 'Nome Amigável da Publicação já existente!',
          showConfirmButton: false,
          timer: 5000
        })
        return
      }
    }
    else {
      const checkDescricao = dashboards?.filter(dash => dash?.Descricao?.toLowerCase() === window.document.getElementById('descricao').value?.toLowerCase())
      if (checkDescricao.length > 0) {
        Swal.fire({
          icon: 'error',
          text: 'Nome da Publicação já existente!',
          showConfirmButton: false,
          timer: 5000
        })
        return
      }

      const checkAmigavel = dashboards?.filter(dash => dash?.NomeAmigavel?.toLowerCase() === window.document.getElementById('amigavel').value?.toLowerCase())
      if (checkAmigavel.length > 0) {
        Swal.fire({
          icon: 'error',
          text: 'Nome Amigável da Publicação já existente!',
          showConfirmButton: false,
          timer: 5000
        })
        return
      }
    }



    if (idDashEdit) {
      await api(`/dashboard/api/atualizaDashboard/${user.cnpj}/${idDashEdit}`, {
        method: 'put',
        data: JSONDashboard
      }).then(async () => {

        Swal.fire({
          icon: 'success',
          text: 'Publicação atualizada com sucesso',
          showConfirmButton: false,
          timer: 3000
        })
        getDashBoards()

        //handleClearInputs()
      })
        .catch(error => {
          console.log(error)
        })
    } else {
      await api('/dashboard/api/query_execute', {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: { SQL_Execute: `SELECT IFNULL(MAX(ID),0) + 1 AS insertId FROM Dashboards WHERE CPF_CNPJ = '${user.cnpj}'` }
      }).then(async (res) => {
        const { insertId } = res.data[0]
        console.log('### insertId')
        console.log(insertId)

        await api(`/dashboard/api/criarDashboard/${user.cnpj}/${insertId}`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          data: JSONDashboard
        }).then(async (res) => {

          setIdDashEdit(insertId)

          uploadFile(null, insertId)

          await InsertUsuariosDashboards(insertId)

          Swal.fire({
            icon: 'success',
            text: 'Publicação criada com sucesso',
            showConfirmButton: false,
            timer: 3000
          })
          setTextSegundaAba('Editando dados da Publicação Nº ' + insertId)
          setTextButtonSave('Atualizar dados')
          getDashBoards()

          //handleClearInputs()
        })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              text: 'Erro ao cadastrar a Publicação. ' + error + ' ' + error?.response?.data?.sqlMessage,
              showConfirmButton: false,
              timer: 5000
            })
            console.log(error)
          })

      })

    }
  }


  const getListaDepartamentos = async () => {
    await api
      .get(`/departamento/api/listaDepartamento/${user.cnpj}`)
      .then(data => {
        setDepartamentos(data.data)
      })
      .catch(error => console.log(error))
  }

  const getDashBoards = async () => {
    await api
      .get(`/dashboard/api/listaDashboard/${user.cnpj}`)
      .then(data => {
        setDashboards(filtrarArray(data.data))
        setDashboards_Full(data.data)
      })
      .catch(error => console.log(error))

    const registrosDashPermitidos = await getListaDashPermitidosUsuario()
    setListaDashPermitidosUsuario(registrosDashPermitidos)
  }

  async function getListaDashPermitidosUsuario() {
    let registros = []
    try {
      await api
        .get(`/usuario/api/listaDashboardUsuario/${user.cnpj}`, { params: { IdUsuario: user?.idUsuario } })
        .then(data => {
          registros = data.data.filter(dash => dash.CheckDash === 1)
        })
        .catch(error => {
          console.log('erro em listaDashboardUsuario')
          console.log(error)
        })
    } catch (error) {
      console.log('erro em getListaDashPermitidosUsuario')
      console.log(error)
    }
    finally {
      return registros
    }
  }

  const getEmpresas = async () => {
    await api
      .get('/api/empresa/getDadosGeral')
      .then(data => {
        setEmpresas(data.data)
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    getEmpresas()
  }, [])



  async function getComposicaoCarrossel(IdDash) {
    await api.get(`/dashboard/api/listaComposicaoCarrossel/${user.cnpj}/${IdDash}`)
      .then(data => {
        const results = data.data
        setComposicaoCarrossel(results)
        console.log('results')
        console.log(results)
      })
      .catch(error => {
        console.log('Erro em getComposicaoCarrossel')
        console.log(error)
      })
  }


  const handleChangeOpTempoAtualizacao = (event) => {
    setOpTempoAtualizacao((event.target).value);
  };

  const handleChangeOpPeriodoInicialPesquisa = (event) => {
    setOpPeriodoInicialPesquisa((event.target).value);
  };

  const handleChangeOpTipoDash = (event) => {
    setOpTipoDash((event.target).value);
  };


  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };


  const uploadFile = async (file, idDashEditParam) => {

    const formData = new FormData()
    const imagefile = document.querySelector('#fileInputDash')

    const extensaoArquivo = imagefile?.files[0]?.name?.split('.')?.slice(-1)[0]

    if (typeof (extensaoArquivo) === 'undefined') {
      return
    }


    if (extensaoArquivo.toString().toLowerCase() !== 'png') {
      return Swal.fire({
        icon: 'error',
        text: 'Arquivo inválido! Selecione um PNG!',
        showConfirmButton: false,
        timer: 5000
      })
    }


    document.getElementById('imgPreviewDash').src = window.URL.createObjectURL(imagefile.files[0])


    if (idDashEditParam === '') {
      return
    }

    formData.append('logodash', imagefile.files[0])

    const API_ENDPOINT = `/dashboard/api/upload-photos-dash/${idDashEditParam}/${extensaoArquivo}`

    await api.post(
      API_ENDPOINT,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(response => {
        Swal.fire({
          icon: 'success',
          text: response.data,
          showConfirmButton: false,
          timer: 3000
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          text: 'Erro ao gravar a Imagem de Fundo. ' + error,
          showConfirmButton: false,
          timer: 5000
        })
        console.log('erro: ' + error)
      })
  }

  function ExportEditor() {
    alert('export')
  }

  async function LimpaCamposCarrossel() {
    setTipoCarrossel('IMAGEM')
    setDashSelecionadoCarrossel('Selecione um Dash')
    setLinkCarrossel('')
    setMinutosCarrossel('')
    window.document.getElementById('ImagemCarrossel').value = null
    window.document.getElementById('VideoCarrossel').value = null
  }

  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false)

  async function handleSalvarComposicao() {
    try {

      if (!idDashEdit) {
        return
      }



      if (ComposicaoCarrossel.length > 0) {
        if (!parseFloat(minutosCarrossel) > 0) {
          Swal.fire({
            icon: 'error',
            text: 'Informe os minutos para a Composição! Já existe um ou mais item lançado.',
            showConfirmButton: false,
            timer: 5000
          })

          return
        }
      }


      if (TipoCarrossel === 'DASH NATIVO' && DashSelecionadoCarrossel === 'Selecione um Dash') {
        Swal.fire({
          icon: 'error',
          text: 'Selecione um Dash para continuar!',
          showConfirmButton: false,
          timer: 5000
        })

        return
      }

      if (TipoCarrossel === 'LINK' && linkCarrossel === '') {
        Swal.fire({
          icon: 'error',
          text: 'Informe um Link para continuar!',
          showConfirmButton: false,
          timer: 5000
        })

        return
      }

      const formData = new FormData()

      const imagefile = document.querySelector('#ImagemCarrossel')

      let vNomeImagem = imagefile?.files[0]?.name || ''

      const VideoFile = document.querySelector('#VideoCarrossel')

      let vNomeVideo = VideoFile?.files[0]?.name || ''

      console.log('VideoFile?.files[0]')
      console.log(VideoFile?.files[0])

      if (TipoCarrossel === 'VÍDEO' && !vNomeVideo) {
        Swal.fire({
          icon: 'error',
          text: 'Selecione um Vídeo para continuar!',
          showConfirmButton: false,
          timer: 5000
        })

        return
      }

      if (TipoCarrossel === 'IMAGEM' && !vNomeImagem) {
        Swal.fire({
          icon: 'error',
          text: 'Selecione uma Imagem para continuar!',
          showConfirmButton: false,
          timer: 5000
        })

        return
      }

      setIsLoadingUploadFile(true)
      setIsLoadingCarrossel(true)

      let vMaxCodigoItem = 0

      await api('/dashboard/api/query_execute', {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: {
          SQL_Execute: `SELECT IFNULL(MAX(CodigoItem),0) + 1 AS MaxCodigoItem FROM ComposicaoCarrossel 
          WHERE IdDashboard = ${idDashEdit} AND CPF_CNPJ = '${user.cnpj}'
          `
        }
      }).then(async (response) => {
        vMaxCodigoItem = response?.data[0]?.MaxCodigoItem || 1

        let nomePersonalizado = ''

        var dataAtual = new Date();
        var dataFormatada =
          dataAtual.getDate().toString().padStart(2, '0') + '-' +
          (dataAtual.getMonth() + 1).toString().padStart(2, '0') + '-' +
          dataAtual.getFullYear().toString() + '-' +
          dataAtual.getHours().toString().padStart(2, '0') + '.' +
          dataAtual.getMinutes().toString().padStart(2, '0') + '.' +
          dataAtual.getSeconds().toString().padStart(2, '0');
        console.log('dataFormatada');
        console.log(dataFormatada);


        if (vNomeImagem) {
          vNomeImagem = `${vNomeImagem.replace('.png', '')}_${dataFormatada}.png`
          formData.append('arquivo-carrossel', imagefile.files[0])
          nomePersonalizado = vNomeImagem
        }

        if (vNomeVideo) {
          vNomeVideo = `${vNomeVideo.replace('.mp4', '')}_${dataFormatada}.mp4`
          formData.append('arquivo-carrossel', VideoFile.files[0])
          nomePersonalizado = vNomeVideo
        }

        console.log('formData')
        console.log(formData)

        console.log('TipoCarrossel')
        console.log(TipoCarrossel)

        if (TipoCarrossel === 'VÍDEO' || TipoCarrossel === 'IMAGEM') {
          await api.post(`/dashboard/api/upload-arquivos-carrossel/${nomePersonalizado}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
            }
          )
            .then(response => {
              console.log('### response.data de upload-arquivos-carrossel')
              console.log(response.data)
            })
            .catch(error => {
              Swal.fire({
                icon: 'error',
                text: 'Erro ao gravar o arquivo do Carrossel.  ' + error,
                showConfirmButton: false,
                timer: 5000
              })
              console.log('erro: em upload-arquivos-carrossel de handleSalvarComposicao ')
              console.log(error)
            })
        }

        await api('/dashboard/api/query_execute', {
          method: 'put',
          headers: { 'Content-Type': 'application/json' },
          data: {
            SQL_Execute: `
            INSERT INTO ComposicaoCarrossel (CPF_CNPJ,IdDashboard, CodigoItem, Tipo, IdDashboardSelecionado, LinkSelecionado, ImagemSelecionada, VideoSelecionado, MinutosDuracao, Ordem) 
            VALUES (
              '${user.cnpj}',
              ${idDashEdit}, (SELECT IFNULL(MAX(AUX.CodigoItem),0) + 1 AS MaxCodigoItem FROM ComposicaoCarrossel AUX WHERE IdDashboard = ${idDashEdit}),
              '${TipoCarrossel}', ${DashSelecionadoCarrossel !== 'Selecione um Dash' ? `${DashSelecionadoCarrossel}` : null}, '${linkCarrossel}',
              '${vNomeImagem}', '${vNomeVideo}', ${minutosCarrossel || 0}, (SELECT IFNULL(MAX(AUX.Ordem),0) + 1 AS MaxOrdem FROM ComposicaoCarrossel AUX WHERE IdDashboard = ${idDashEdit})
            );`
          }
        }).then(async () => {
          await getComposicaoCarrossel(idDashEdit)
          Swal.fire('Sucesso', 'Composição salva', 'success')
          await LimpaCamposCarrossel()
        }).catch(error => {
          console.log(error)
          Swal.fire({
            icon: 'error',
            text: 'Erro ao cadastrar a Composição. ' + error,
            showConfirmButton: false,
            timer: 5000
          })
        })
      }).catch(error => {
        console.log(error)
        Swal.fire({
          icon: 'error',
          text: 'Erro ao cadastrar a Composição. ' + error,
          showConfirmButton: false,
          timer: 5000
        })
      })
    } catch (error) {
      console.log('Erro em handleSalvarComposicao')
      console.log(error)
    }
    finally {
      setIsLoadingUploadFile(false)
      setIsLoadingCarrossel(false)
    }
  }


  async function handleExcluirComposicao(vCodigoItem, Tipo, vNomeArquivo) {
    Swal.fire({
      title: 'Deseja excluir este Cadastro de Composição?',
      text: 'Também será excluido o Vídeo/Imagem caso tenha!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (Tipo === 'VÍDEO' || Tipo === 'IMAGEM') {
          const urlDel = `/dashboard/api/excluir-arquivo-carrossel/${vNomeArquivo}`
          await api(urlDel, {
            method: 'delete',
            headers: { 'Content-Type': 'application/json' },
          }).then(() => {

          }).catch(error => {
            console.log(error)
          })
        }

        await api('/dashboard/api/query_execute', {
          method: 'put',
          headers: { 'Content-Type': 'application/json' },
          data: {
            SQL_Execute: `DELETE FROM ComposicaoCarrossel WHERE IdDashboard = ${idDashEdit} AND CodigoItem = ${vCodigoItem} AND CPF_CNPJ = '${user.cnpj}'`
          }
        }).then(async () => {

          await api('/dashboard/api/query_execute', {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: {
              SQL_Execute: `
              UPDATE Composicaocarrossel c
                JOIN (
                    SELECT CodigoItem, IdDashboard,CPF_CNPJ, @new_order := @new_order + 1 AS new_order
                    FROM (
                        SELECT CodigoItem, IdDashboard, CPF_CNPJ
                        FROM Composicaocarrossel
                        WHERE IdDashboard = ${idDashEdit} AND CPF_CNPJ = '${user.cnpj}'
                        ORDER BY Ordem
                    ) AS sub
                    CROSS JOIN (SELECT @new_order := 0) AS init
                ) AS ordered_records
                ON c.CodigoItem = ordered_records.CodigoItem AND c.IdDashboard = ordered_records.IdDashboard AND C.CPF_CNPJ = ordered_records.CPF_CNPJ
                SET c.Ordem = ordered_records.new_order
                WHERE c.IdDashboard = ${idDashEdit} AND C.CPF_CNPJ = '${user.cnpj}' ;
              `
            }
          }).then(async () => {
            await getComposicaoCarrossel(idDashEdit)
            Swal.fire('Sucesso', 'Composição Excluída', 'success')
          }).catch(error => {
            console.log(error)
            Swal.fire({
              icon: 'error',
              text: 'Erro ao Excluir a Composição. ' + error,
              showConfirmButton: false,
              timer: 5000
            })
          })
        }).catch(error => {
          console.log(error)
          Swal.fire({
            icon: 'error',
            text: 'Erro ao Excluir a Composição. ' + error,
            showConfirmButton: false,
            timer: 5000
          })
        })
      }
    })
  }

  async function handleSubirOrdemComposicao(vCodigoItem, vOrdem) {
    if (vOrdem === 1) {
      return
    }

    try {
      setIsLoadingCarrossel(true)

      await api('/dashboard/api/query_execute', {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: {
          SQL_Execute: `
            UPDATE ComposicaoCarrossel
            SET Ordem = Ordem - 1
            WHERE IdDashboard = ${idDashEdit} AND CodigoItem = ${vCodigoItem} AND CPF_CNPJ = '${user.cnpj}'
          `
        }
      }).then(async () => {
        //await getComposicaoCarrossel(idDashEdit)
        await api('/dashboard/api/query_execute', {
          method: 'put',
          headers: { 'Content-Type': 'application/json' },
          data: {
            SQL_Execute: `
              UPDATE ComposicaoCarrossel
              SET Ordem = Ordem + 1
              WHERE IdDashboard = ${idDashEdit} AND CPF_CNPJ = '${user.cnpj}' AND CodigoItem <> ${vCodigoItem} AND Ordem = ${vOrdem - 1}
            `
          }
        }).then(async () => {
          await getComposicaoCarrossel(idDashEdit)
        }).catch(error => {
          console.log(error)
          Swal.fire({
            icon: 'error',
            text: 'Erro ao Mudar a ordem da Composição. ' + error,
            showConfirmButton: false,
            timer: 5000
          })
        })
      }).catch(error => {
        console.log(error)
        Swal.fire({
          icon: 'error',
          text: 'Erro ao Mudar a ordem da Composição. ' + error,
          showConfirmButton: false,
          timer: 5000
        })
      })
    } catch (error) {
      console.log('Erro em handleSubirOrdemComposicao')
      console.log(error)
    }
    finally {
      setIsLoadingCarrossel(false)
    }
  }

  async function handleDescerOrdemComposicao(vCodigoItem, vOrdem) {
    if (vOrdem === ComposicaoCarrossel.length) {
      return
    }

    try {
      setIsLoadingCarrossel(true)

      await api('/dashboard/api/query_execute', {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        data: {
          SQL_Execute: `
            UPDATE ComposicaoCarrossel
            SET Ordem = Ordem + 1
            WHERE IdDashboard = ${idDashEdit} AND CodigoItem = ${vCodigoItem} AND CPF_CNPJ = '${user.cnpj}'
          `
        }
      }).then(async () => {
        //await getComposicaoCarrossel(idDashEdit)
        await api('/dashboard/api/query_execute', {
          method: 'put',
          headers: { 'Content-Type': 'application/json' },
          data: {
            SQL_Execute: `
              UPDATE ComposicaoCarrossel
              SET Ordem = Ordem - 1
              WHERE IdDashboard = ${idDashEdit} AND CPF_CNPJ = '${user.cnpj}' AND CodigoItem <> ${vCodigoItem} AND Ordem = ${vOrdem + 1}
            `
          }
        }).then(async () => {
          await getComposicaoCarrossel(idDashEdit)
        }).catch(error => {
          console.log(error)
          Swal.fire({
            icon: 'error',
            text: 'Erro ao Mudar a ordem da Composição. ' + error,
            showConfirmButton: false,
            timer: 5000
          })
        })
      }).catch(error => {
        console.log(error)
        Swal.fire({
          icon: 'error',
          text: 'Erro ao Mudar a ordem da Composição. ' + error,
          showConfirmButton: false,
          timer: 5000
        })
      })
    } catch (error) {
      console.log('Erro em handleDescerOrdemComposicao')
      console.log(error)
    }
    finally {
      setIsLoadingCarrossel(false)
    }
  }


  const renderAbaCadastrarNovoDash = () => {
    return (
      <div className='border border-thirdColor p-2 rounded-lg '>

        <div className=' my-2 flex flex-row gap-4  h-full'>
          <div className='w-[45%]'>
            <div className='flex flex-col   gap-4 mb-4 '>

              <div className='divDepartamento'>
                <p className='text-sm text-primaryColor '>
                  CATEGORIA:
                </p>
                <div className='flex flex-row  '>
                  <select
                    required
                    onChange={event => setDepartamento(event.target.value)} id='departamento' className={`${styles.selectDepartamento} uppercase bg-gray-50 border border-gray-300  text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block  p-0.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}>
                    <option selected>Selecione uma Categoria</option>
                    {
                      departamentos.sort((a, b) => (a.Descricao > b.Descricao) ? 1 : -1).map(departamento => {
                        return (
                          <option key={departamento.Descricao} value={departamento.Descricao}>{departamento.Descricao}</option>
                        )
                      })
                    }
                  </select>
                </div>
                {
                  departamento === 'Selecione uma Categoria' ?
                    <p style={{ fontSize: 11, color: 'red', marginTop: '0.2rem' }} >* Informe uma Categoria</p>
                    : null
                }
              </div>


              <div className='divNomeDash w-full' >
                <div className='flex flex-col w-full'>
                  <p className='text-sm text-primaryColor'>
                    NOME DA PUBLICAÇÃO:
                  </p>
                  <input autocomplete="off" placeholder='NOME PARA A PUBLICAÇÃO' onChange={event => setDescricao(event.target.value)} id='descricao' className='uppercase pl-1 rounded-md shadow-lg  text-sm focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor' />
                  {
                    !descricao ?
                      <p style={{ fontSize: 11, color: 'red', marginTop: '0.2rem' }} >* Digite um Nome</p>
                      : null
                  }
                </div>
              </div>

              <div className='divNomeAmigavel w-full' >
                <div className='flex flex-col w-full'>
                  <p className='text-sm text-primaryColor'>
                    NOME AMIGÁVEL:
                  </p>
                  <input autocomplete="off" placeholder='NOME AMIGÁVEL PARA A PUBLICAÇÃO' onChange={event => setNomeAmigavel(event.target.value)} id='amigavel' className='uppercase pl-1 rounded-md shadow-lg  text-sm focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor' />
                  {
                    !nomeAmigavel ?
                      <p style={{ fontSize: 11, color: 'red', marginTop: '0.2rem' }} >* Digite um Nome Amigável</p>
                      : null
                  }
                </div>
              </div>

            </div>

            <div className={`${styles.divBotaoSave} w-full  flex justify-end mt-10`}>
              <button
                className={`${styles.botaoSave} bg-white-100  ml-2 text-sm flex flex-row justify-center  items-center hover:bg-red-500 text-red-500 font-semibold hover:text-white-100 py-1 px-2 border border-red-500 rounded shadow-lg`}
                onClick={() => handleClearInputs()}
              >
                <p className='text-xl truncate' >🚫</p> &nbsp; Limpar formulário
              </button>

              <button
                style={{
                  opacity: disable ? 0.5 : 1
                }}
                className={`${styles.botaoSave} bg-white-100 ${disable && 'cursor-not-allowed'} truncate ml-2 text-sm flex flex-row justify-center  items-center hover:bg-primaryColor text-primaryColor font-semibold hover:text-white-100 py-1 px-2 border border-primaryColor rounded shadow-lg`}
                disabled={disable}
                onClick={() => criaDashboard()}
              >
                <CloudSave width="2em" height="2em" /> &nbsp; {textButtonSave}
              </button>
            </div>
          </div>

          <div className='flex flex-row gap-[1rem] w-[55%]' >
            <div className={`${opTipoDash === 'CARROSSEL' ? 'flex flex-col' : 'hidden'}  w-full h-fit p-2 shadow-lg rounded-[0.5rem] border border-thirdColor`}>
              <p className='text-sm text-primaryColor'>
                COMPOSIÇÃO DO CARROSSEL:
              </p>

              <div className=' flex flex-col gap-[1rem] mt-1' >
                <div className='h-[30vh] min-h-[30vh] overflow-scroll ' >
                  <table className='w-full'>
                    <thead className='sticky top-0'>
                      <tr className='bg-primaryColor text-sm text-white-100 text-center'>
                        <th className='px-2 truncate'>Opções</th>
                        <th className='px-2 truncate'>Ordem</th>
                        <th className='px-2 truncate'>Tipo</th>
                        <th className='px-2 truncate'>Exibindo</th>
                        <th className='px-2 truncate'>Minutos</th>

                      </tr>
                    </thead>
                    <tbody>
                      {ComposicaoCarrossel.length > 0 ? (
                        ComposicaoCarrossel.sort((a, b) => (a.Ordem > b.Ordem) ? 1 : -1).map((dashs, key) => (
                          <tr key={key} className='odd:bg-white-100 even:bg-lightgray text-sm hover:bg-gray-300'>
                            <td className='truncate text-center px-2 !bg-white-200'>
                              {/* <button className='xl:text-sm 2xl:text-base hover:bg-blue-900 text-blue-900 font-semibold hover:text-white-100 py-1 px-2 border border-blue-900 rounded mr-1'
                                onClick={() => handleExcluirComposicao(dashs.CodigoItem, dashs.Tipo, dashs.Exibindo)}>
                                <FeatherIcon icon="edit" size={15} />
                              </button> */}

                              <button className='xl:text-sm 2xl:text-base hover:bg-blueInn-100 text-blueInn-100 font-semibold hover:text-white-100 py-1 px-2 border border-blueInn-100 rounded mr-1'
                                onClick={() => handleSubirOrdemComposicao(dashs.CodigoItem, dashs.Ordem)}>
                                <FeatherIcon icon="arrow-up" size={15} />
                              </button>

                              <button className='xl:text-sm 2xl:text-base hover:bg-black text-black font-semibold hover:text-white-100 py-1 px-2 border border-black rounded mr-1'
                                onClick={() => handleDescerOrdemComposicao(dashs.CodigoItem, dashs.Ordem)}>
                                <FeatherIcon icon="arrow-down" size={15} />
                              </button>

                              <button className='xl:text-sm 2xl:text-base hover:bg-red-500 text-red-500 font-semibold hover:text-white-100 py-1 px-2 border border-red-500 rounded mr-1'
                                onClick={() => handleExcluirComposicao(dashs.CodigoItem, dashs.Tipo, dashs.Exibindo)}>
                                <FeatherIcon icon="trash-2" size={15} />
                              </button>

                            </td>
                            <td className='truncate text-center px-2' >
                              {dashs.Ordem}
                            </td>
                            <td className='truncate text-left px-2 flex flex-row gap-[0.5rem] items-center ' >
                              {dashs.Tipo === 'DASH NATIVO' ?
                                <FeatherIcon icon="grid" size={15} />
                                : null
                              }
                              {dashs.Tipo === 'LINK' ?
                                <FeatherIcon icon="link" size={15} />
                                : null
                              }
                              {dashs.Tipo === 'VÍDEO' ?
                                <FeatherIcon icon="youtube" size={15} />
                                : null
                              }
                              {dashs.Tipo === 'IMAGEM' ?
                                <FeatherIcon icon="image" size={15} />
                                : null
                              }
                              {dashs.Tipo}
                            </td>
                            <td className='truncate text-left px-2' >{dashs.Exibindo}</td>
                            <td className='truncate text-right px-2'>{dashs.MinutosDuracao}</td>

                          </tr>
                        ))
                      ) : (
                        <tr className='bg-white-100 w-full'>
                          <td className='text-center italic text-sm' colSpan="5">Nenhum registro encontrado</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='flex flex-row gap-[1rem] '>

                  <div className=''>
                    <p style={{ opacity: idDashEdit ? 1 : 0.3 }} className='text-sm text-primaryColor '>
                      TIPO:
                    </p>
                    <select
                      style={{ opacity: idDashEdit ? 1 : 0.3 }}
                      required
                      disabled={idDashEdit ? false : true}
                      value={TipoCarrossel}
                      onChange={event => {
                        if (!idDashEdit) {
                          return
                        }
                        setTipoCarrossel(event.target.value)
                      }}
                      id='TipoCarrossel'
                      className={`w-full uppercase bg-gray-50 border border-gray-300  text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block  p-0.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    >
                      <option key={'IMAGEM'} value={'IMAGEM'}>{'IMAGEM'}</option>
                      <option key={'VÍDEO'} value={'VÍDEO'}>{'VÍDEO'}</option>
                    </select>

                    <div className=' w-full mt-2 '>
                      <p style={{ opacity: idDashEdit ? 1 : 0.3 }} className='text-xs text-primaryColor'>
                        MINUTOS
                      </p>
                      <input
                        style={{ opacity: idDashEdit ? 1 : 0.3 }}
                        autoComplete="off"
                        disabled={idDashEdit ? false : true}
                        maxLength={5} // Permitindo até 5 caracteres, por exemplo, 0.30
                        value={minutosCarrossel}
                        onChange={(event) => {
                          if (!idDashEdit) {
                            return
                          }
                          const value = event.target.value;
                          // Verificar se o valor inserido é um número ou um número seguido de ponto e mais números
                          if (/^\d*\.?\d*$/.test(value)) {
                            setMinutosCarrossel(value);
                          }
                        }}
                        onKeyPress={(e) => {
                          // Permitir apenas dígitos e o ponto
                          if (!/[\d.]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        id='minutosCarrossel'
                        className='w-full text-center uppercase pl-1 rounded-md shadow-lg text-sm focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor'
                      />

                    </div>
                  </div>

                  <div className='w-full'>
                    <p style={{ opacity: TipoCarrossel === 'IMAGEM' && idDashEdit ? 1 : 0.3 }} className='text-sm text-primaryColor mt-2 flex flex-row gap-[0.5rem] items-center'>
                      <FeatherIcon icon="image" size={15} /> IMAGEM
                    </p>
                    <label className="block">
                      <input
                        accept="image/png"
                        disabled={TipoCarrossel === 'IMAGEM' && idDashEdit ? false : true}
                        style={{
                          cursor: TipoCarrossel === 'IMAGEM' && idDashEdit ? 'pointer' : 'not-allowed',
                          opacity: TipoCarrossel === 'IMAGEM' && idDashEdit ? 1 : 0.3
                        }}
                        className={'file:cursor-pointer block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border file:text-sm file:font-semibold file:bg-white-100 file:border-primaryColor file:text-primaryColor hover:file:bg-primaryColorFocus hover:file:text-white-100'}
                        type="file"
                        id="ImagemCarrossel"
                      />
                    </label>

                    <p style={{ opacity: TipoCarrossel === 'VÍDEO' && idDashEdit ? 1 : 0.3 }} className='text-sm text-primaryColor mt-2 flex flex-row gap-[0.5rem] items-center'>
                      <FeatherIcon icon="youtube" size={15} /> VÍDEO
                    </p>
                    <label className="block">
                      <input
                        accept="video/*"
                        disabled={TipoCarrossel === 'VÍDEO' && idDashEdit ? false : true}
                        style={{
                          cursor: TipoCarrossel === 'VÍDEO' && idDashEdit ? 'pointer' : 'not-allowed',
                          opacity: TipoCarrossel === 'VÍDEO' ? 1 : 0.3
                        }}
                        className={'file:cursor-pointer block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border file:text-sm file:font-semibold file:bg-white-100 file:border-primaryColor file:text-primaryColor hover:file:bg-primaryColorFocus hover:file:text-white-100'}
                        type="file"
                        id="VideoCarrossel"
                      />
                    </label>

                    <button
                      className='w-full mt-[1rem] gap-[0.5rem] rounded  border border-green-500 text-green-500 hover:bg-green-500 hover:text-white-200  flex items-center justify-center p-[0.5rem] '
                      onClick={() => handleSalvarComposicao()}
                      style={{ opacity: idDashEdit && !isLoadingUploadFile ? 1 : 0.3 }}
                      disabled={idDashEdit && !isLoadingUploadFile ? false : true}
                    >
                      <FeatherIcon icon="plus-circle" size={25} />
                      <p className='font-bold text-sm ' >
                        {isLoadingUploadFile ?
                          'Salvando Composição, aguarde...'
                          :
                          'Salvar Composição'
                        }
                      </p>
                      {isLoadingUploadFile ?
                        <FeatherIcon icon="refresh-cw" size={25} />
                        :
                        null
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>





        </div>
      </div>
    )
  }

  async function handleRenderLinkDash(dash) {

    //localStorage.removeItem("dashboardRecents")

    const storedDashboardRecents = localStorage.getItem('dashboardRecents');

    let dashList = [];

    // Verifica se há dados no localStorage
    if (storedDashboardRecents) {
      // Se houver dados, converte para array e atribui a dashList
      dashList = JSON.parse(storedDashboardRecents);
    }

    const newDashboard = {
      user: user,
      Descricao: dash.Descricao,
      LinkDash: dash.LinkDash,
      NomeAmigavel: dash.NomeAmigavel,
      timestamp: new Date().toISOString()
    };

    // Remove da lista caso exista
    dashList = dashList.filter((dashboard) => dashboard.Descricao !== newDashboard.Descricao)

    dashList.push(newDashboard);

    // Salva a lista atualizada no localStorage
    localStorage.setItem('dashboardRecents', JSON.stringify(dashList));

    //const registrosDashPermitidos = await getListaDashPermitidosUsuario()

    const registrosDashPermitidos = await getListaDashPermitidosUsuario()

    if (registrosDashPermitidos.filter(d => d.Descricao == dash.Descricao).length > 0) {
      if (dash.LinkDash) {
        addTab('renderlinkdash')
        setLinkDashContext(dash.LinkDash)
      }
      else {
        addTab(dash.Descricao)
      }
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Publicação não habilitada nas configurações de Usuário!',
        showConfirmButton: false,
        timer: 5000
      })
    }

  }

  function handleNewTab(dash) {
    localStorage.manterLogin = "true"
    localStorage.DescricaoDashAtual = dash.Descricao
  }



  const renderAbaListaDash = () => {
    return (
      <>

        <ListaDashboards
          Altura='h-[60vh]'
          dashFiltro={dashFiltro}
          setDashfiltro={setDashfiltro}
          dashboards={dashboards}
          showEditButtom={true}
          encheCamposTela={encheCamposTela}
          excluiDashBoard={excluiDashBoard}
          handleRenderLinkDash={handleRenderLinkDash}
          handleNewTab={handleNewTab}
        />
        {
          /* 
          <div className={`${styles.tabelas}  overflow-x-hidden overflow-y-scroll border border-thirdColor div-scroll-hidden `}>
          <table className={`w-full border-collapse div-scroll-hidden  `}>
            <thead>
              <tr className='bg-primaryColor text-white-100 font-semibold text-sm'>
                <th className='md:w-5 text-center'>Editar / Excluir</th>
                <th className='w-7'>Id</th>
                <th className='md:w-28 text-left'>Nome do Dash</th>
                <th className='md:w-36 text-left'>Nome Amigável</th>
                <th className='md:w-36 text-left'>Departamento</th>
              </tr>
            </thead>
            <tbody className='text-xs xl:text-sm uppercase'>
              {dashboards.length > 0 ? (
                dashboards.map((dashboard, key) => (
                  <tr key={key} className='odd:bg-white-100 even:bg-lightgray text-sm'>
                    <td className='flex flex-row justify-center py-2'>
                      <button className='xl:text-sm 2xl:text-base hover:bg-primaryColor text-primaryColor font-semibold hover:text-white-100 py-1 px-2 border border-primaryColor rounded mr-1'
                        onClick={() => encheCamposTela(key)}>
                        <FeatherIcon icon="edit" size={14} />
                      </button>
                      <button className='xl:text-sm 2xl:text-base hover:bg-red-500 text-red-500 font-semibold hover:text-white-100 py-1 px-2 border border-red-500 rounded'
                        onClick={() => excluiDashBoard(key)}>
                        <FeatherIcon icon="trash" size={14} />
                      </button>
                    </td>
                    <td className='text-center'>{dashboard.Id}</td>
                    <td>{dashboard.Descricao}</td>
                    <td>{dashboard.NomeAmigavel}</td>
                    <td>{dashboard.Departamento}</td>
                  </tr>
                ))
              ) : (
                <tr className='bg-white-100 w-full'>
                  <td className='text-center italic text-sm' colSpan="5">Nenhum registro encontrado</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
          
          */
        }
      </>

    )
  }

  return (
    <div className="screensDash div-scroll-hidden h-full">
      <div className="flex flex-row h-full w-full div-scroll-hidden ">
        <div className="flex flex-col h-full  w-full overflow-y-auto div-scroll-hidden ">
          <BarraDeTitulo Titulo={'CADASTRO DE PUBLICAÇÕES'} />
          {isLoadingCarrossel ?
            <LoadingSpinner />
            : <></>
          }
          <div className="px-5 p-4 flex flex-col max-h-[86.5%] overflow-auto ">
            <div className="containerGuia scrollable">
              <div className="bloc-tabs">
                <button
                  className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(1)}
                >
                  {`Lista de Publicações (${dashboards.length || 0})`}
                </button>
                <button
                  className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(2)}
                >
                  {textSegundaAba}
                </button>
              </div>
              <div className="content-tabs">
                <div
                  className={toggleState === 1 ? "content  active-content" : "content"}
                >
                  {renderAbaListaDash()}
                </div>
                <div
                  className={` ${toggleState === 2 ? "content  active-content" : "content"}`}
                >
                  {renderAbaCadastrarNovoDash()}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
