/* eslint-disable multiline-ternary */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import { api } from '../../services/api'
import { useContext, useEffect, useState } from 'react'
import { BarraDeTitulo } from '../../components/BarraDeTitulo'
import FeatherIcon from 'feather-icons-react'
import Swal from 'sweetalert2'
import styles from "./stylesDepartamentos.module.css"
import MyContext from '../../contexts/myContext'

export default function Departamentos() {
  const [departamento, setDepartamento] = useState([])
  const { user } = useContext(MyContext)

  const [descricao, setDescricao] = useState('')
  const [descricaoEdit, setDescricaoEdit] = useState('')

  useEffect(() => {
    getDepartamento()
  }, [])

  function encheCamposTela(descricao) {
    setDescricaoEdit(departamento[descricao].Descricao)

    window.document.getElementById('departamentoCadastro').value = departamento[descricao].Descricao
  }

  async function criaDepartamento() {
    if (window.document.getElementById('departamentoCadastro').value === '') {
      Swal.fire({
        title: 'Digite a Descrição do Departamento!',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      getDepartamento()
      handleClearInputs()
    } else {
      if (departamento.filter(Id => Id.Descricao === descricao).length > 0) {
        Swal.fire({
          title: 'Descrição já existente!',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        getDepartamento()
        handleClearInputs()
      } else {
        const criaDepartamento = {
          departamento: window.document.getElementById('departamentoCadastro').value
        }

        if (departamento.filter(Id => Id.Descricao === descricaoEdit).length > 0) {
          const Id = departamento.filter(Id => Id.Descricao === descricaoEdit)

          const atualizarDepartamento = {
            Id: Id[0].Id,
            departamento: window.document.getElementById('departamentoCadastro').value
          }
          await api({
            method: 'put',
            url: `/departamento/api/atualizaDepartamento/${user.cnpj}`,
            data: atualizarDepartamento
          }).then(() => {
            Swal.fire({
              icon: 'success',
              text: 'Cadastro atualizado com sucesso',
              showConfirmButton: false,
              timer: 3000
            })
            getDepartamento()
            handleClearInputs()
          })
            .catch(error => {
              console.log(error)
            })
        } else {
          await api({
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            url: `/departamento/api/criarDepartamento/${user.cnpj}`,
            data: criaDepartamento
          }).then(() => {
            Swal.fire({
              icon: 'success',
              text: 'Cadastro criado com sucesso',
              showConfirmButton: false,
              timer: 3000
            })
            getDepartamento()
            handleClearInputs()
          })
            .catch(error => {
              Swal.fire({
                icon: 'error',
                text: 'Erro ao cadastrar o Departamento. ' + error + ' ' + error?.response?.data?.sqlMessage,
                showConfirmButton: false,
                timer: 5000
              })
              console.log(error)
            })
        }
      }
    }
  }

  function excluiDepartamento(departamentos) {
    const excluiDepartamento = { Id: departamento[departamentos].Id }
    Swal.fire({
      title: 'Deseja excluir essa Categoria?',
      text: 'Você não será capaz de reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        api({
          method: 'delete',
          headers: { 'Content-Type': 'application/json' },
          url: `/departamento/api/excluirDepartamento/${user.cnpj}`,
          data: excluiDepartamento
        }).then((data) => {

          if (data?.data?.affectedRows > 0) {
            Swal.fire('Categoria', 'Excluída com sucesso', 'success')
            window.document.getElementById('departamentoCadastro').value = ''
            getDepartamento()
            handleClearInputs()
          }
          else {
            Swal.fire('Atenção', 'Não foi possível Excluir a Categoria. Verifique se o mesmo não tem Publicações vinculadas e tente novamente.', 'warning')
          }


        })
          .catch(error => {
            console.log(error)
          })
      }
    })
  }

  function handleClearInputs() {
    setDepartamento('')
  }

  const getDepartamento = async () => {
    await api
      .get(`/departamento/api/listaDepartamento/${user.cnpj}`)
      .then(data => {
        setDepartamento(data.data)
      })
      .catch(error => console.log(error))
  }

  return (
    <div className='screensDash'>
      <div className="flex flex-row h-full w-full">
        <div className="flex flex-col h-full bg-gray-100 w-full overflow-y-auto">
          <BarraDeTitulo Titulo={'CADASTRO DE CATEGORIAS'} />
          <div className="h-full px-5 py-2 flex flex-col overflow-auto bg-gray-100">
            <p className='text-xs md:text-sm 2xl:text-base text-primaryColor'>
              CATEGORIA:
            </p>
            <div className='flex flex-row'>
              <input onChange={(e) => setDescricao(e.target.value)} id="departamentoCadastro" className={`${styles.inputDepartamento}  uppercase pl-1 rounded shadow-lg text-xs md:text-sm 2xl:text-base focus:outline-none focus:border-primaryColor focus:ring-1 focus:ring-primaryColor`} />
              <button className='flex align-bottom ml-2 w-8 bg-white-100 md:text-sm 2xl:text-base justify-center lg:justify-start items-center hover:bg-primaryColor text-primaryColor font-semibold hover:text-white-100 py-1 px-2 border border-primaryColor rounded shadow-lg`'
                onClick={() => criaDepartamento()}>
                <FeatherIcon icon="plus" size={16} />
              </button>
            </div>
            <div className="mt-5 border border-thirdColor overflow-auto">
              <table className='w-full'>
                <thead>
                  <tr className='bg-primaryColor text-white-100 font-semibold text-xs md:text-base 2xl:text-lg'>
                    <th className='md:w-36 text-center text-xs md:text-base 2xl:text-lg text-white-100'>Ações</th>
                    <th className='w-10'>ID</th>
                    <th className='text-left' >Descrição</th>
                  </tr>
                </thead>
                <tbody className='text-xs xl:text-sm uppercase'>
                  {departamento.length > 0 ? (
                    departamento.map((departamento, key) => (
                      <tr key={key} className='odd:bg-white-100 even:bg-lightgray'>
                        <td className='flex flex-row justify-center py-2'>
                          <button className='xl:text-sm 2xl:text-base hover:bg-primaryColor text-primaryColor font-semibold hover:text-white-100 py-1 px-2 border border-primaryColor rounded mr-1'
                            onClick={() => encheCamposTela(key)}>
                            <FeatherIcon icon="edit" size={14} />
                          </button>
                          <button className='xl:text-sm 2xl:text-base hover:bg-red-500 text-red-500 font-semibold hover:text-white-100 py-1 px-2 border border-red-500 rounded'
                            onClick={() => excluiDepartamento(key)}>
                            <FeatherIcon icon="trash" size={14} />
                          </button>
                        </td>
                        <td className='text-center'>{departamento.Id}</td>
                        <td>{departamento.Descricao}</td>

                      </tr>
                    ))
                  ) : (
                    <tr className='bg-white-100 w-full'>
                      <td className='text-center italic text-sm' colSpan="3">Nenhum registro encontrado</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
