import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css'; // Importe o estilo CSS do editor

function RenderDescritivoDashHelp(props) {

    return (
        <div className='p-2 h-full w-full ' >
            {props?.dash.LinkHelp !== '' ?
                <a
                    className="w-full hover:font-bold text-center p-8"
                    href={props?.dash.LinkHelp}
                    rel="noreferrer"
                    target="_blank"
                >
                    <p className='text-blue-900' >Clique aqui e acesse o link com o conteúdo de apoio:</p>
                    <p className='text-red-600' >{props?.dash.LinkHelp}</p>
                </a>
                :
                <ReactQuill
                    theme="bubble"
                    value={props?.dash.DescritivoDash}
                    //onChange={handleTextChange}
                    modules={{ toolbar: false }}
                />
            }

        </div>
    )
}

export default RenderDescritivoDashHelp
