import React, { useContext, useState } from 'react'
import Swal from 'sweetalert2'
import CadastroDashBoard from '../CadastroDashBoard';
import CadastroAgendamentos from '../CadastroAgendamentos';
import Carrossel from '../../Carrossel/Carrossel';
import { useEffect } from 'react';
import CadastroImagens from '../CadastroImagens';
import { api } from '../../services/api';
import MyContext from '../../contexts/myContext';
import Departamentos from '../Departamentos';
import CadastroEmpresas from '../CadastroEmpresas';
import CadastroUsuarios from '../CadastroUsuarios';
import DashPorDepartamento from '../DashPorDepartamento';
import RenderLinkDash from '../RenderLinkDash';
import { CabecalhoAbas } from '../../components/CabecalhoAbas';
import RenderDescritivoDashHelp from '../RenderDescritivoDashHelp';
import styles from "./stylesHome.module.css"
import { SVGDashboard } from '../../public/SVG/SVGDashboard';

function Home() {

    const [dadosDash, setDadosDash] = useState([])

    const { tabsAbertas, user, tabAtual, Departamento, LinkDash, addTab, setDepartamento, setLinkDash } = useContext(MyContext)

    const getDadosDashBoard = async () => {
        await api
            .get(`/dashboard/api/listaDashboard/${user.cnpj}`)
            .then(data => {
                setDadosDash(data.data)
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getDadosDashBoard()
    }, [tabAtual])

    useEffect(() => {
        if (tabAtual === 'OPÇÕES' || tabAtual === '') { /* OPÇÕES = HOME  */
            getDashboardsRecentes()
        }
    }, [tabAtual])

    const [listaDepartamentos, setListaDepartamentos] = useState([])
    const [ListaDashPermitidosUsuario, setListaDashPermitidosUsuario] = useState([])

    async function getListaDepartamentos() {
        await api
            .get(`/usuario/api/listaDepartamentosusuario/${user?.cnpj}/${user?.idUsuario}`)
            .then(data => {
                setListaDepartamentos(data.data)
            })
            .catch(error => console.log(`Erro em listaDepartamentosusuario. ${error}`))

        await api
            .get(`/usuario/api/listaDashboardUsuario/${user.cnpj}`, { params: { IdUsuario: user?.idUsuario } })
            .then(data => {
                setListaDashPermitidosUsuario(data.data.filter(dash => dash.CheckDash === 1))
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        if (user) {
            getListaDepartamentos()
        }
    }, [user])


    function renderCabecalhoAbas() {
        return (
            <CabecalhoAbas dadosDash={dadosDash} />
        )
    }

    function renderTabsTelasNativas() {
        return (<>
            {tabsAbertas.filter(aberta => aberta.name === 'renderlinkdash').length > 0 ?
                <div className={`${tabAtual === 'renderlinkdash' ? 'flex' : 'hidden'}`}>
                    <RenderLinkDash LinkDash={LinkDash} />
                </div>
                :
                null
            }

            {tabsAbertas.filter(aberta => aberta.name.includes('AJUDA -')).map(tab => {
                return (
                    <div className={`${tabAtual === tab.name ? 'flex' : 'hidden'}`}>
                        <RenderDescritivoDashHelp dash={dadosDash.filter(d => d.NomeAmigavel.toUpperCase() === tab.name.toUpperCase().replace('AJUDA - ', ''))[0]} />
                    </div>
                )
            })}


            {tabsAbertas.filter(aberta => aberta.name === 'dashpordepartamento').length > 0 ?
                <div className={`${tabAtual === 'dashpordepartamento' ? 'flex h-full' : 'hidden'}`}>
                    <DashPorDepartamento Departamento={Departamento} />
                </div>
                :
                null
            }

            {tabsAbertas.filter(aberta => aberta.name === 'departamentos').length > 0 ?
                <div className={`${tabAtual === 'departamentos' ? 'flex' : 'hidden'}`}>
                    <Departamentos />
                </div>
                :
                null
            }

            {tabsAbertas.filter(aberta => aberta.name === 'cadastrodashboard').length > 0 ?
                <div className={`${tabAtual === 'cadastrodashboard' ? 'flex h-full' : 'hidden'}`}>
                    <CadastroDashBoard />
                </div>
                :
                null
            }

            {tabsAbertas.filter(aberta => aberta.name === 'CadastroAgendamentos').length > 0 ?
                <div className={`${tabAtual === 'CadastroAgendamentos' ? 'flex h-full' : 'hidden'}`}>
                    <CadastroAgendamentos />
                </div>
                :
                null
            }

            {tabsAbertas.filter(aberta => aberta.name === 'cadastroimagens').length > 0 ?
                <div className={`${tabAtual === 'cadastroimagens' ? 'flex' : 'hidden'}`}>
                    <CadastroImagens />
                </div>
                :
                null
            }

            {tabsAbertas.filter(aberta => aberta.name === 'cadastroempresas').length > 0 ?
                <div className={`${tabAtual === 'cadastroempresas' ? 'flex' : 'hidden'}`}>
                    <CadastroEmpresas />
                </div>
                :
                null
            }

            {tabsAbertas.filter(aberta => aberta.name === 'cadastrousuarios').length > 0 ?
                <div className={`${tabAtual === 'cadastrousuarios' ? 'flex h-full' : 'hidden'}`}>
                    <CadastroUsuarios />
                </div>
                :
                null
            }

        </>)
    }


    function renderTabsDashboards() {
        return (<>

            {dadosDash.filter(aberta => aberta.Descricao === tabAtual && aberta.opTipoDash === 'CARROSSEL').length > 0 ?
                dadosDash.filter(tab => tab.Descricao === tabAtual && tab.opTipoDash === 'CARROSSEL').map(tab => {
                    return (
                        <div className={`${tab.Descricao === tabAtual ? 'flex h-full' : 'hidden'}`}>
                            <Carrossel
                                NomeAmigavel={tab.NomeAmigavel}
                                NomeInterno={tab.Descricao}
                            />
                        </div>
                    )
                })

                :
                null
            }
        </>)
    }

    const [listaDashRecentes, setListaDashRecentes] = useState([])

    async function getDashboardsRecentes() {
        const storedDashboardRecents = localStorage.getItem('dashboardRecents');

        let dashList = [];

        // Verifica se há dados no localStorage
        if (storedDashboardRecents) {
            // Se houver dados, converte para array e atribui a dashList
            dashList = JSON.parse(storedDashboardRecents);
        }

        setListaDashRecentes(dashList.filter(d => d.user.idUsuario == user.idUsuario && d.user.cnpj == user.cnpj))

    }

    async function handleVerDash(dash) {
        if (ListaDashPermitidosUsuario.filter(d => d.Descricao == dash.Descricao).length > 0) {
            if (dash.LinkDash) {
                addTab('renderlinkdash')
                setLinkDash(dash.LinkDash)
            }
            else {
                addTab(dash.Descricao)
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                text: 'Dash não habilitado nas configurações de Usuário!',
                showConfirmButton: false,
                timer: 5000
            })
        }


    }

    function trataGuiasScroll_OK() {
        let vRetorno = false
        switch (tabAtual) {
            case 'dashpordepartamento':
                vRetorno = true
                break;
            case 'cadastrousuarios':
                vRetorno = true
                break;
            case 'cadastrodashboard':
                vRetorno = true
                break;
            case 'CadastroAgendamentos':
                vRetorno = true
                break;
            default:
                break;
        }
        return vRetorno
    }


    return (
        <div className="home max-h-screen overflow-hidden bg-transparent">
            {renderCabecalhoAbas()}

            <div className={`${trataGuiasScroll_OK() === true ? 'w-full h-full' : 'max-h-[93.5vh] overflow-scroll'} ${tabsAbertas.length === 1 ? 'hidden' : 'flex'}`}>
                <div className={`${trataGuiasScroll_OK() === true ? 'w-full' : 'h-full w-full overflow-scroll div-scroll-hidden'} `} >
                    {renderTabsTelasNativas()}
                    {renderTabsDashboards()}
                </div>
            </div>
            <div className={`h-[93%] ${tabAtual === 'OPÇÕES' || tabAtual === '' ? 'flex' : 'hidden'}`} >
                {/* 
                    <IframeDashURL URL="https://inntegra.com.br/" />
                */}
                <div className={`${styles.divHome}`}>
                    <div className='flex flex-row gap-2 items-center' >
                        <p className={` text-white-100 ${styles.textOla} `}>Olá, </p>
                        <p className={` text-white-100 ${styles.userName} `}>{user.Nome}</p>
                    </div>

                    {/* <div className={`${styles.divTitle}`}>

                        <p className={` text-white-100 whitespace-nowrap ${styles.title} `}>Bem vindo ao Mais Prático</p>
                        <p className={` text-gray-200 ${styles.subTitle} `}>KPI</p>
                    </div> */}

                    <div className={`${styles.divMain}`}>
                        <div className={`${styles.divDashRecentes}`} >
                            <div className='flex flex-row gap-3 items-center '>
                                <div className='text-2xl'>
                                    <SVGDashboard color={'white'} />
                                </div>
                                <p className={` text-white-100 whitespace-nowrap ${styles.titleDepartamento} `}>Publicações Recentes</p>
                            </div>
                            <p className={` text-white-100 ${styles.textInfo} `}>Publicações usadas neste Navegador pelo seu usuário</p>
                            <div className={`${styles.divScrollItens}`}>
                                {
                                    listaDashRecentes.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map(dash => {
                                        return (
                                            <p onClick={() => {
                                                handleVerDash(dash)
                                            }} className={` text-white-100 ${styles.nomeDepartamento} `}>{dash.NomeAmigavel.toUpperCase()}</p>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className={`${styles.divDepartamentos}`} >
                            <p className={` text-white-100 whitespace-nowrap ${styles.titleDepartamento} `}>Suas Categorias</p>
                            <p className={` text-white-100 ${styles.textInfo} `}>Categorias liberadas para seu usuário</p>
                            <div className={`${styles.divScrollItens}`}>
                                {listaDepartamentos.map(dep => {
                                    return (
                                        <p onClick={() => {
                                            addTab('dashpordepartamento')
                                            setDepartamento(dep.Departamento)
                                        }} className={` text-white-100 ${styles.nomeDepartamento} `}>{dep.Departamento}</p>
                                    )
                                })}
                            </div>

                        </div>


                    </div>
                </div>

                {/* <a className='absolute text-end right-6 mb-2 underline text-white-200 w-full bottom-0' href={`https://inntegra.com.br/`} rel="noreferrer" target="_blank">https://inntegra.com.br</a> */}
            </div>
        </div>
    )
}

export default Home
