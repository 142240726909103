import { useContext, useEffect, useState, useLayoutEffect } from 'react'


import { MenuLateralEncolhido } from './MenuLateralEncolhido'
import { MenuLateralExpandido } from './MenuLateralExpandido'
import { api } from '../services/api'
import MyContext from '../contexts/myContext'


export default function MenuLateral(props) {
  const { user, barraLateral, setBarraLateral } = useContext(MyContext)
  
  const [listaDepartamentos, setListaDepartamentos] = useState(false)

  function switchBarraLateral() {
    setBarraLateral(!barraLateral)
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }


  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useLayoutEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize, { passive: true });
      return () => window.removeEventListener('resize', handleResize, { passive: true });
    }, []);

    return windowDimensions;
  }

  async function listaDashboardUsuarios() {
    await api
      .get(`/usuario/api/listaDepartamentosusuario/${user?.cnpj}/${user?.idUsuario}`)
      .then(data => {
        setListaDepartamentos(data.data)
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    listaDashboardUsuarios()
  }, [])

  return (
    <>
      <div
        style={{ height: '100%', display: barraLateral ? 'block' : 'none', }} >
        <MenuLateralEncolhido listaDepartamentos={listaDepartamentos} switchBarraLateral={() => switchBarraLateral()} />
      </div>

      <div className='' style={{ height: '100%', display: barraLateral ? 'none' : 'block' }}>
        <MenuLateralExpandido listaDepartamentos={listaDepartamentos} switchBarraLateral={() => switchBarraLateral()} />
      </div>

    </>
  )
}
