
import { React, useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import FeatherIcon from 'feather-icons-react'
import styles from "./styles.module.css"
import './estilos.css';  // Importe o arquivo de estilos
import { api } from '../../../../services/api';
import LoadingSpinner from '../../../../components/Loanding/LoadingSpinner';
import MyContext from '../../../../contexts/myContext';

export function ModalAgendamento(props) {
    const { user } = useContext(MyContext)
    const [dateTimeInicio, setDateTimeInicio] = useState('');
    const [dateTimeFinal, setDateTimeFinal] = useState('');
    const [RegistrosAgendamentos, setRegistrosAgendamentos] = useState([]);

    const [DashSelecionadoCarrossel, setDashSelecionadoCarrossel] = useState('Selecione uma Publicação')
    const [DashPadraoSelecionadoCarrossel, setDashPadraoSelecionadoCarrossel] = useState('Selecione uma Publicação Padrão')
    const [dashboards_Full, setDashboards_Full] = useState([])
    const [IsLoading, setIsLoading] = useState(false)
    const [IsLoadingSaveAgendamento, setIsLoadingSaveAgendamento] = useState(false)

    // Função para lidar com a mudança do input
    const handleDateTimeInicioChange = (event) => {
        setDateTimeInicio(event.target.value);
    };



    // Função para lidar com a mudança do input
    const handleDateTimeFinalChange = (event) => {
        setDateTimeFinal(event.target.value);
    };

    const getDashBoards = async () => {
        try {
            setIsLoading(true)
            await api
                .get(`/dashboard/api/listaDashboard/${user.cnpj}`)
                .then(data => {
                    setDashboards_Full(data.data)
                })
                .catch(error => console.log(error))
        } catch (error) {
            console.log('Erro em getDashBoards')
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getDashBoards()
    }, [])

    async function HandleSaveAgendamento(_dateTimeInicio, _dateTimeFinal) {
        try {
            setIsLoadingSaveAgendamento(true)
            setIsLoading(true)

            if (_dateTimeInicio.length !== 0 && _dateTimeFinal.length === 0) {
                return Swal.fire({
                    icon: 'error',
                    title: 'Validação',
                    text: 'Preencha a data final!'
                })
            }
            if (_dateTimeInicio.length === 0 && _dateTimeFinal.length !== 0) {
                return Swal.fire({
                    icon: 'error',
                    title: 'Validação',
                    text: 'Preencha a data Inicial!'
                })
            }
            if (_dateTimeInicio > _dateTimeFinal) {
                return Swal.fire({
                    icon: 'error',
                    title: 'Validação',
                    text: 'A data inicial não pode ser maior que a data final!'
                })
            }

            const [dateInicio, timeInicio] = _dateTimeInicio.split('T');
            const [dateFinal, timeFinal] = _dateTimeFinal.split('T');

            const [yearInicio, monthInicio, dayInicio] = dateInicio.split('-');
            const [yearFinal, monthFinal, dayFinal] = dateFinal.split('-');

            const formattedDateTimeInicio = `${yearInicio}-${monthInicio}-${dayInicio} ${timeInicio}:00`;

            const formattedDateTimeFinal = `${yearFinal}-${monthFinal}-${dayFinal} ${timeFinal}:00`;

            await api('/dashboard/api/query_execute', {
                method: 'put',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    SQL_Execute: `
                        SELECT *
                        FROM Agendamentos
                        WHERE
                        CodigoDispositivo = (SELECT CodigoDispositivo FROM Dispositivos AUX WHERE CPF_CNPJ = '${user.cnpj}' AND DescricaoDispositivo = '${props.DispositivoAtual}')
                        AND CPF_CNPJ = '${user.cnpj}'

                        AND
                        (
                            (DATE('${formattedDateTimeInicio}') BETWEEN DATE(DataInicial) AND DATE(DataFinal)) OR 
                            (DATE('${formattedDateTimeFinal}') BETWEEN DATE(DataInicial) AND DATE(DataFinal))
                        )
                        AND
                        (
                            (TIME('${formattedDateTimeInicio}') < TIME(DataFinal) AND TIME('${formattedDateTimeFinal}') > TIME(DataInicial))
                        )

                        
                        
                    `
                }
            }).then(async (response) => {
                console.log('response.data da verificação')
                console.log(response.data)

                if (response.data.length > 0) {
                    Swal.fire({
                        icon: 'error',
                        text: 'Já existe Agendamentos nesse período para este Dispositivo! Verifique a Data e Hora e tente novamente.',
                        showConfirmButton: false,
                        timer: 8000,
                        customClass: {
                            popup: 'custom-swal'  // Adicione a classe personalizada
                        },

                    })
                }
                else {
                    await api('/dashboard/api/query_execute', {
                        method: 'put',
                        headers: { 'Content-Type': 'application/json' },
                        data: {
                            SQL_Execute: `
                                INSERT INTO Agendamentos (
                                    CPF_CNPJ,
                                    CodigoAgendamento,
                                    CodigoDispositivo,
                                    DataInicial,
                                    DataFinal,
                                    CodigoDash
                                )
                                VALUES (
                                    '${user.cnpj}',
                                    (SELECT IFNULL(MAX(CodigoAgendamento),0) + 1 AS MasCodigo FROM Agendamentos AUX WHERE CPF_CNPJ = '${user.cnpj}'),
                                    (SELECT CodigoDispositivo FROM Dispositivos AUX WHERE CPF_CNPJ = '${user.cnpj}' AND DescricaoDispositivo = '${props.DispositivoAtual}'),
                                    '${formattedDateTimeInicio}',
                                    '${formattedDateTimeFinal}',
                                    ${DashSelecionadoCarrossel}
                                )
                            `
                        }
                    }).then(async (response) => {
                        await getRegistrosAgendamento()
                        setDashSelecionadoCarrossel('Selecione uma Publicação')
                        setDateTimeInicio('')
                        setDateTimeFinal('')

                    })
                        .catch(error => {
                            Swal.fire({
                                icon: 'error',
                                text: 'Erro ao gravar o Agendamento.  ' + error,
                                showConfirmButton: false,
                                timer: 5000
                            })
                            console.log('erro em HandleSaveAgendamento')
                            console.log(error)
                        })
                }
            })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Erro ao gravar o Agendamento.  ' + error,
                        showConfirmButton: false,
                        timer: 5000
                    })
                    console.log('erro em HandleSaveAgendamento')
                    console.log(error)
                })
        } catch (error) {
            console.log('Erro em HandleSaveAgendamento')
            console.log(error)
        }
        finally {
            setIsLoadingSaveAgendamento(false)
            setIsLoading(false)
        }
    }

    async function handleSavePublicacaoPadraoDispositivo(IdDashboardPadrao) {
        try {
            setIsLoading(true)

            let vIdDashboardPadrao = IdDashboardPadrao && IdDashboardPadrao !== 'Selecione uma Publicação Padrão' ? IdDashboardPadrao : null

            await api('/dashboard/api/query_execute', {
                method: 'put',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    SQL_Execute: `
                        UPDATE Dispositivos SET IdDashboardPadrao = ${vIdDashboardPadrao}
                        WHERE CPF_CNPJ = '${user.cnpj}' AND DescricaoDispositivo = '${props.DispositivoAtual}'
                    `
                }
            }).then(async (response) => {
                console.log('response.data da handleSavePublicacaoPadraoDispositivo')
                console.log(response.data)
                Swal.fire({
                    icon: 'success',
                    text: 'Padrão Salvo com sucesso!',
                    showConfirmButton: false,
                    timer: 5000
                })
            })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Erro ao gravar a Publicação Padrão.  ' + error,
                        showConfirmButton: false,
                        timer: 5000
                    })
                    console.log('erro em handleSavePublicacaoPadraoDispositivo')
                    console.log(error)
                })
        } catch (error) {
            console.log('Erro em handleSavePublicacaoPadraoDispositivo')
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }

    async function getRegistrosAgendamento() {
        try {
            setIsLoading(true)

            await api('/dashboard/api/query_execute', {
                method: 'put',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    SQL_Execute: `
                        SELECT A.CPF_CNPJ AS CPF_CNPJ, A.CodigoAgendamento AS CodigoAgendamento, A.CodigoDash AS CodigoDash,
                        DATE_FORMAT(A.DataInicial,'%d/%m/%Y - %H:%i') AS DataInicial,
                        DATE_FORMAT(A.DataFinal,'%d/%m/%Y - %H:%i') AS DataFinal,
                        UCASE(D.Descricao) AS DescricaoDash
                        FROM Agendamentos A INNER JOIN Dashboards D ON A.CodigoDash = D.Id AND A.CPF_CNPJ = D.CPF_CNPJ
                        WHERE A.CPF_CNPJ = '${user.cnpj}' AND A.CodigoDispositivo = (SELECT CodigoDispositivo FROM Dispositivos WHERE CPF_CNPJ = '${user.cnpj}' AND DescricaoDispositivo = '${props.DispositivoAtual}')
                        ORDER BY A.DataInicial
                    `
                }
            }).then(async (response) => {
                setRegistrosAgendamentos(response.data)
            })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Erro ao Buscar os Agendamentos.  ' + error,
                        showConfirmButton: false,
                        timer: 5000
                    })
                    console.log('erro em getRegistrosAgendamento')
                    console.log(error)
                })
        } catch (error) {
            console.log('Erro em getRegistrosAgendamento')
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }

    async function getDashPadraoDispositivo() {
        try {
            setIsLoading(true)

            await api('/dashboard/api/query_execute', {
                method: 'put',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    SQL_Execute: `
                        SELECT IFNULL(IdDashboardPadrao,'Selecione uma Publicação Padrão') AS IdDashboardPadrao
                        FROM Dispositivos
                        WHERE CPF_CNPJ = '${user.cnpj}' AND DescricaoDispositivo = '${props.DispositivoAtual}'
                    `
                }
            }).then(async (response) => {
                setDashPadraoSelecionadoCarrossel(response.data[0].IdDashboardPadrao)
            })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Erro ao Buscar a Publicação Padrão.  ' + error,
                        showConfirmButton: false,
                        timer: 5000
                    })
                    console.log('erro em getDashPadraoDispositivo')
                    console.log(error)
                })
        } catch (error) {
            console.log('Erro em getDashPadraoDispositivo')
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getRegistrosAgendamento()
        getDashPadraoDispositivo()
    }, [])

    async function handleExcluirAgendamento(Agendamento) {
        try {
            setIsLoading(true)

            await api('/dashboard/api/query_execute', {
                method: 'put',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    SQL_Execute: `
                        DELETE FROM Agendamentos WHERE CodigoAgendamento = ${Agendamento.CodigoAgendamento} AND CPF_CNPJ = '${user.cnpj}'
                    `
                }
            }).then(async (response) => {
                await getRegistrosAgendamento()
            })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Erro ao Excluir o Agendamento.  ' + error,
                        showConfirmButton: false,
                        timer: 5000
                    })
                    console.log('erro em handleExcluirAgendamento')
                    console.log(error)
                })
        } catch (error) {
            console.log('Erro em handleExcluirAgendamento')
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }

    const RenderItemAgendamento = (props) => {
        return (
            <div key={props.Agendamento.CodigoAgendamento} className='flex flex-row gap-[1.5rem] m-[0.5rem] hover:bg-gray-300'>
                <button className='xl:text-sm 2xl:text-base hover:bg-red-500 text-red-500 font-semibold hover:text-white-100 py-1 px-2 border border-red-500 rounded mr-1'
                    onClick={() => handleExcluirAgendamento(props.Agendamento)}>
                    <FeatherIcon icon="trash-2" size={15} />
                </button>
                <div className='w-[30%]'>
                    {props.Agendamento.DescricaoDash}
                </div>
                <div className=''>
                    {props.Agendamento.DataInicial}
                </div>
                <div className=''>
                    {'Até'}
                </div>
                <div className=''>
                    {props.Agendamento.DataFinal}
                </div>

            </div>
        )
    }

    return (
        <>

            <div className={`${styles.modal}`}>
                <div className={`${styles.divAnimation} flex flex-col w-full h-fit bg-white-200 rounded-xl overflow-hidden shadow-lg`} >
                    <div class="flex items-center justify-between p-5 border-b rounded-t bg-gradient-to-r from-slate-900 to-slate-700 dark:border-gray-600">
                        <h3 class="text-xl font-medium text-white-200 dark:text-white">
                            Dispositivo: <strong className='' >{props.DispositivoAtual}</strong>
                        </h3>

                        <div className='flex ml-[2rem] gap-[0.5rem] items-center w-[50%] '>
                            <div className='text-white-200 whitespace-nowrap'>Publicação Padrão:</div>
                            <select
                                required
                                value={DashPadraoSelecionadoCarrossel}
                                onChange={event => {
                                    setDashPadraoSelecionadoCarrossel(event.target.value)
                                }}
                                id='DashPadraoSelecionadoCarrosselAgendamento'
                                className={`w-full h-[2.75rem] uppercase bg-gray-50 border border-gray-300  text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block  p-0.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            >
                                <option selected>Selecione uma Publicação Padrão</option>
                                {
                                    dashboards_Full.map(d => {
                                        return (
                                            <option key={d.Id} value={d.Id}>{d.NomeAmigavel}</option>
                                        )
                                    })
                                }
                            </select>

                            <button
                                className={`w-[40%] h-fit gap-[0.5rem] rounded  border border-green-500 text-green-500 hover:bg-green-500 hover:text-white-200  flex items-center justify-center p-[0.5rem]`}
                                onClick={() => handleSavePublicacaoPadraoDispositivo(DashPadraoSelecionadoCarrossel)}
                            >
                                <FeatherIcon icon="upload-cloud" size={25} />
                                <p className='font-bold text-sm whitespace-nowrap' >
                                    Salvar Padrão
                                </p>
                            </button>
                        </div>

                        <button onClick={() => props.setDispositivoAtual('')} type="button" class="text-white-200 bg-transparent hover:bg-red-500 hover:text-gray-100 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-red-500 dark:hover:text-white" data-modal-hide="small-modal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className=' relative p-2 h-full flex flex-row gap-[1.5rem]'>
                        <div className='w-[30%]'>
                            <div className='text-gray-700'>Publicação</div>
                            <select
                                required
                                value={DashSelecionadoCarrossel}
                                onChange={event => {
                                    setDashSelecionadoCarrossel(event.target.value)
                                }}
                                id='DashSelecionadoCarrosselAgendamento'
                                className={`w-full h-[2.75rem] uppercase bg-gray-50 border border-gray-300  text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block  p-0.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            >
                                <option selected>Selecione uma Publicação</option>
                                {
                                    dashboards_Full.map(d => {
                                        return (
                                            <option key={d.Id} value={d.Id}>{d.NomeAmigavel}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div>
                            <div className='text-gray-700'>Data e Hora (Início)</div>
                            <input
                                type="datetime-local"
                                value={dateTimeInicio}
                                onChange={handleDateTimeInicioChange}
                                className="border p-2 rounded"
                            />
                        </div>

                        <div>
                            <div className='text-gray-700'>Data e Hora (Fim)</div>
                            <input
                                type="datetime-local"
                                value={dateTimeFinal}
                                onChange={handleDateTimeFinalChange}
                                className="border p-2 rounded"
                            />
                        </div>

                        <div className='w-[20%] flex items-center'>
                            <button
                                className={`w-full h-fit gap-[0.5rem] ${IsLoadingSaveAgendamento || dateTimeInicio === '' || dateTimeFinal === '' || DashSelecionadoCarrossel === 'Selecione uma Publicação' ? 'cursor-not-allowed' : ''} rounded  border border-green-500 text-green-500 hover:bg-green-500 hover:text-white-200  flex items-center justify-center p-[0.5rem]`}
                                onClick={() => HandleSaveAgendamento(dateTimeInicio, dateTimeFinal)}
                                style={{ opacity: !IsLoadingSaveAgendamento && dateTimeInicio !== '' && dateTimeFinal !== '' && DashSelecionadoCarrossel !== 'Selecione uma Publicação' ? 1 : 0.6 }}
                                disabled={!IsLoadingSaveAgendamento && dateTimeInicio !== '' && dateTimeFinal !== '' && DashSelecionadoCarrossel !== 'Selecione uma Publicação' ? false : true}
                            >
                                <FeatherIcon icon="plus-circle" size={25} />
                                <p className='font-bold text-sm ' >
                                    {IsLoadingSaveAgendamento ?
                                        'Agendar...'
                                        :
                                        'Agendar'
                                    }
                                </p>
                                {IsLoadingSaveAgendamento ?
                                    <FeatherIcon icon="refresh-cw" size={25} />
                                    :
                                    null
                                }
                            </button>
                        </div>
                    </div>

                    <div className='border m-[0.5rem] p-[0.5rem] bg-white-100 border-gray-700 rounded-md min-h-[35vh] max-h-[35vh] overflow-auto'>
                        {RegistrosAgendamentos.map(e => {
                            return (
                                <RenderItemAgendamento Agendamento={e} />
                            )
                        })}
                    </div>

                    <div className='mx-[0.5rem] mb-[0.5rem] text-xs text-gray-700 font-bold ' >{`Registros: ${RegistrosAgendamentos.length}`}</div>
                </div>

            </div>
            {IsLoading ?

                <div className='absolute w-full h-full z-[9989]'>
                    <LoadingSpinner />
                </div>

                : <></>
            }
        </>
    )
}
