import React, { memo } from 'react'
import { api } from '../../../services/api'

function ReturnDashVideo(props) {
    const vSrc = `${api.defaults.baseURL}/dashboard/api/videocarrossel/${props.vCarrossel.Exibindo}`

    if (props.vCarrossel) {
        return (
            <div key={props.vCarrossel.Exibindo} className={``} style={{ width: '100%', height: '100%' }}>
                <video
                    key={vSrc}
                    controls={props.controls}
                    autoPlay={true}
                    loop
                    muted
                    width="100%"
                    style={{ height: '100%', width: '100%', objectFit: 'fill', maxHeight: props.hideBar ? '100vh' : '92vh' }}
                    className='w-full'
                >
                    <source
                        src={vSrc}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            </div>
        )
    }
}

export default memo(ReturnDashVideo)
