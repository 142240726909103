import React from 'react'
import IframeDashURL from '../../components/IframeDashURL'

function RenderLinkDash(props) {
    return (
        <div className="home min-h-screen overflow-hidden">
            <>
                <IframeDashURL URL={props?.history?.location?.state || props?.LinkDash} />
            </>
        </div>
    )
}

export default RenderLinkDash
