/* eslint-disable multiline-ternary */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { BarraDeTitulo } from '../../components/BarraDeTitulo'
import Swal from 'sweetalert2'
import { CloudSave } from '../../public/SVG/CloudSave'
import { ArrowLeft } from '../../public/SVG/ArrowLeft'
import { IconAddImage } from '../../public/SVG/IconAddImage'
import FeatherIcon from 'feather-icons-react'
import { api } from '../../services/api'
import styles from "./stylesCadastroImagens.module.css"
import IconPNG from "../../public/PNG/IconPNG.png"
import TargetIcon from "../../public/PNG/TargetIcon.png"

export default function CadastroImagens() {
  const [listaDeMidias, setListaDeMidias] = useState([])
  const [disableButtonAddImagemMidia, setDisableButtonAddImagemMidia] = useState(true)
  const [nomeMidia, setNomeMidia] = useState('')

  useEffect(() => {
    getListaMidias()
  }, [])


  function encheCamposTela(descMidia) {
    setNomeMidia(descMidia)
    setDisableButtonAddImagemMidia(false)
    const urlIMG = `${api.defaults.baseURL}/midias/api/getimagemmidia/${descMidia}`
    
    document.getElementById('imgPreview').src = urlIMG
  }

  const getListaMidias = async () => {
    await api
      .get('/midias/api/listamidias')
      .then(data => {
        setListaDeMidias(data.data)
      })
      .catch(error => console.log(error))
  }

  function handleCancelar() {
    setDisableButtonAddImagemMidia(true)
    setNomeMidia('')
    setListaDeMidias([])
    getListaMidias()
    document.getElementById('imgPreview').src = IconPNG
  }

  const uploadFile = async file => {
    if (!nomeMidia) {
      return Swal.fire({
        icon: 'error',
        text: 'Informe a mídia!',
        showConfirmButton: false,
        timer: 5000
      })
    }

    const formData = new FormData()
    const imagefile = document.querySelector('#fileInput')

    const extensaoArquivo = imagefile?.files[0]?.name?.split('.')?.slice(-1)[0]

    if (typeof (extensaoArquivo) === 'undefined') {
      return
    }

    if (extensaoArquivo.toString().toLowerCase() !== 'png') {
      return Swal.fire({
        icon: 'error',
        text: 'Arquivo inválido! Selecione um PNG!',
        showConfirmButton: false,
        timer: 5000
      })
    }

    const API_ENDPOINT = `midias/api/upload-photos-midia/${nomeMidia}/${extensaoArquivo}`
    formData.append('imagemmidia', imagefile.files[0])
    document.getElementById('imgPreview').src = window.URL.createObjectURL(imagefile.files[0])

    await api.post(
      API_ENDPOINT,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(response => {
        Swal.fire({
          icon: 'success',
          text: response.data,
          showConfirmButton: false,
          timer: 3000
        })
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          text: 'Erro ao gravar a Imagem. ' + error,
          showConfirmButton: false,
          timer: 5000
        })
        console.log('erro: ' + error)
      })
  }

  function getImgMidia(midia) {
    try {
      const img = `${api.defaults.baseURL}/midias/api/getimagemmidia/${midia.Descricao}`
      
      return img
    } catch (error) {
      return TargetIcon
    }
  }


  return (
    <div className="screensDash">
      <div className="flex flex-row h-full w-full">
        <div className="flex flex-col h-full w-full overflow-y-auto bg-white-200 ">
          <BarraDeTitulo Titulo={'CADASTRO DE IMAGENS'} />

          <div className="h-full px-5 p-4 flex flex-col   overflow-auto ">
            <div className='border  border-thirdColor p-4 rounded-lg'>
              <div>
                <div className='text-sm md:text-base 2xl:text-lg  text-primaryColor font-semibold'>
                  Lista de Mídias {nomeMidia && `(${nomeMidia})`}
                </div>

                <div className='h-96 flex flex-row gap-4 mt-2 ' >
                  <div
                    disabled={!disableButtonAddImagemMidia}
                    style={{
                      opacity: !disableButtonAddImagemMidia ? 0.6 : 1,
                      pointerEvents: !disableButtonAddImagemMidia ? 'none' : '',
                    }}
                    className='rounded-lg  border border-x-thirdColor border-t-thirdColor border-b-gray-100 overflow-y-auto'>
                    <table className='w-full'>
                      <thead className='sticky top-0'>
                        <tr className='bg-primaryColor text-xs md:text-base 2xl:text-lg text-white-100 text-left'>
                          <th className='w-16 text-center'>Editar</th>
                          <th className='w-16 text-center'>Id</th>
                          <th className='w-96'>Mídia</th>
                          <th className='w-24 text-center'>Imagem</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listaDeMidias.length > 0 ? (
                          listaDeMidias.sort((a, b) => (a.Codigo > b.Codigo) ? 1 : -1).map((midia, key) => (
                            <tr style={{ backgroundColor: nomeMidia === midia.Descricao ? ' #5468a7 ' : '' }} key={key} className={`odd:bg-white-100 even:bg-white-200 uppercase`}>
                              <td className='flex flex-row justify-center py-2'>
                                <button className='xl:text-sm 2xl:text-base hover:bg-primaryColor text-primaryColor font-semibold hover:text-white-100 py-1 px-2 border border-primaryColor rounded mr-1'
                                  onClick={() => encheCamposTela(midia.Descricao)}>
                                  <FeatherIcon icon="edit" size={14} />
                                </button>
                              </td>
                              <td className='text-right pr-3' >{midia.Codigo}</td>
                              <td >{midia.Descricao}</td>
                              <td className='text-center p-4' >
                                <img
                                  id="imgMidiaTable"
                                  src={getImgMidia(midia)}
                                  onError={(e) => { e.target.onError = null; e.target.src = TargetIcon }}
                                  className='h-8 w-full '
                                  alt=''
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className='bg-white-100 w-full'>
                            <td className='text-center italic text-sm' colSpan="4">Nenhuma Mídia encontrada</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div
                    style={{ borderRadius: '0.5rem', marginTop: '0.5rem' }}
                    className={`${styles.containerLogo} border border-thirdColor  flex flex-col shadow-lg`}
                  >
                    <div style={{ padding: '1rem' }} className={`${styles.divLogoEmpresa}`}>
                      <div className=' text-sm xl:text-base 2xl:text-lg text-primaryColor font-semibold mb-1'>
                        Imagem de exibição no Dash
                      </div>
                      <div style={{}} className='bg-white-100 rounded-lg p-4 shadow-lg w-full h-full flex items-center justify-center'>
                        <img
                          id="imgPreview"
                          src={IconPNG}
                          onError={(e) => { e.target.onError = null; e.target.src = IconPNG }}
                          className='h-10 w-10 xl:w-14 xl:h-14 2xl:h-24 2xl:w-24'
                          alt=''
                        />
                      </div>
                      <div className='text-xs mt-2 text-primaryColor '>
                        * Selecione um arquivo (PNG)
                      </div>
                      <label className="block mt-3">
                        <input
                          accept="image/png"
                          disabled={disableButtonAddImagemMidia}
                          style={{
                            cursor: disableButtonAddImagemMidia ? 'not-allowed' : 'pointer',
                            opacity: disableButtonAddImagemMidia ? 0.5 : 1
                          }}
                          className={'file:cursor-pointer block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border file:text-sm file:font-semibold file:bg-white-100 file:border-primaryColor file:text-primaryColor hover:file:bg-primaryColorFocus hover:file:text-white-100'}
                          type="file"
                          id="fileInput"
                          onChange={(e) => uploadFile(e.target.files)}
                        />
                      </label>

                      <div className='w-full mt-2 flex flex-row gap-2' >


                        <button
                          disabled={disableButtonAddImagemMidia}
                          style={{
                            fontSize: '16px',
                            width: '100%',
                            justifyContent: 'center',
                            opacity: disableButtonAddImagemMidia ? 0.5 : 1
                          }} className={`${styles.botaoSalvar} bg-white-100 ${disableButtonAddImagemMidia && 'cursor-not-allowed'}  md:text-sm 2xl:text-base flex flex-row justify-center lg:justify-start items-center hover:bg-rose-600 text-rose-600 font-semibold hover:text-white-100 py-1 border border-rose-600 rounded-lg shadow-lg`}
                          onClick={() => handleCancelar()}>
                          <ArrowLeft width="1.3em" height="1.3em" /> &nbsp; Voltar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='text-sm mt-2 text-primaryColor '>
                  * Selecione uma Mídia para cadastrar uma imagem a ser exibida nos Dashs!
                </div>

              </div>
            </div>
          </div>


        </div>
      </div>
    </div >
  )
}
