
import { useContext } from "react"
import { Link } from "react-router-dom"
import MyContext from "../../contexts/myContext"
export function SubTituloMenu(props) {
    const { addTab, setDepartamento } = useContext(MyContext)
    //const { replace } = useRouter()
    return (
        <Link
            onClick={() => {
                props.handleSetMenuExpand()
                if (props?.Page) {
                    addTab(props?.Page)
                    if (props.Departamento) {
                        setDepartamento(props.Departamento)
                    }
                }
            }}
            //to={props?.Page}
            style={{
                marginBottom: '0.3rem',

                width: props.Encolhido ? '10rem' : 'auto',
                marginLeft: 30,
                borderRadius: '0.3rem'
            }}
            className="text-ellipsis cursor-pointer pl-1 flex flex-row w-full h-8  shadow-gray-900 hover:bg-gray-500"
        //onClick={() => replace(props?.Page)}
        >
            <p style={{ fontSize: '0.7rem', fontWeight: 'bold', }} className="select-none flex items-center ml-2  text-white-100">
                {props.SubTitulo}
            </p>
        </Link>
    )
}